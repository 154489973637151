import React, { ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { ActionButtons } from '../sidePanel/ActionButtons';
import { CreateKloudProviderInputs } from '../Providers/CreateKloudProvider';
import styles from './FormProvider.module.css';

interface IFormProviderProps<T extends FieldValues> {
  methods: UseFormReturn<T, object>
  onSubmit: () => void;
  handleCancel: () => void;
  isDisabled: boolean;
  children: ReactNode;
  acceptTitle?: string;
}

const FormProviderComponent = <T extends FieldValues>({
  methods,
  onSubmit,
  handleCancel,
  isDisabled,
  children,
  acceptTitle
}: IFormProviderProps<T>) => {

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className={styles.wrapper}>
        {children}
        <ActionButtons
          handleCancel={handleCancel}
          confirmTitle={acceptTitle || 'Сохранить'}
          handleConfirm={onSubmit}
          disabled={isDisabled}
        />
      </form>
    </FormProvider>
  );
};

export const SharedFormProvider = (FormProviderComponent);