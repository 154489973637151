import Box from '@mui/material/Box';
import clsx from 'clsx';
import React, { CSSProperties, FC } from 'react';
import { formatDate } from 'src/helpers';
import { TShortClient } from 'src/redux/services/clients';
import { CustomTypography } from '../custom/CustomTypography';
import { Card } from '../shared/Card';
import styles from './CardCatalog.module.css';

interface CardCatalogProps {
  data: TShortClient[];
  index: number;
  style: CSSProperties;
  height: number;
  padding: number;
}

const CardCatalog: FC<CardCatalogProps> = ({ 
  data, 
  index, 
  style, 
  height,
  padding
}) => {
  const { avatar, name, description, created_at, domain } = data[index] || {};

  return (
    <Card
      height={height}
      padding={padding}
      style={style}
      avatarUrl={avatar}
      isImage
      onClick={() => window.open(domain, '_blank')}
      content={
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.clientInfo}>
              <Box className={styles.clientMainInfo}>
                <CustomTypography className={styles.clientTitle}>
                  {name || 'Loading...'}
                </CustomTypography>
                <CustomTypography color="grey" className={clsx('text-12')}>
                  {formatDate(created_at) || 'Loading...'}
                </CustomTypography>
              </Box>
              <div className={styles.clientAddInfo}>
                {description && (
                  <div className={styles.clientDescription}>
                    <CustomTypography color="grey" className={clsx('text-12')}>
                      Описание:
                    </CustomTypography>
                    <CustomTypography className={clsx('text-12')}>{description}</CustomTypography>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default CardCatalog;
