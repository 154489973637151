import React from 'react';
import Button from '@mui/material/Button';
import styles from './ActionButtons.module.css';

interface IActionButtons {
  handleCancel: () => void;
  confirmTitle: string;
  handleConfirm: () => void;
  disabled?: boolean;
}
export const ActionButtons = ({
  handleCancel,
  confirmTitle,
  handleConfirm,
  disabled,
}: IActionButtons) => {
  return (
    <div>
      <div className={styles.divider} />
      <div className={styles.buttonWrapper}>
        <Button onClick={handleCancel} variant="custom" color="secondary">
          Отмена
        </Button>
        <Button
          style={{ marginLeft: 24 }}
          disabled={disabled}
          variant="custom"
          type="submit"
          onClick={handleConfirm}
        >
          {confirmTitle}
        </Button>
      </div>
    </div>
  );
};
