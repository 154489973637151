import Chip from '@mui/material/Chip';
import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import { formatDate } from 'src/helpers';
import { TLogger } from 'src/redux/services/logger';
import { CustomTypography } from '../custom/CustomTypography';
import { Card } from '../shared/Card';
import styles from './CardEventLog.module.css';

interface CardEventLogProps {
  data: TLogger[];
  index: number;
  style: CSSProperties;
  height: number;
  padding: number;
  onClick: () => void;
  isPersonal?: boolean;
}

const CardEventLog: React.FC<CardEventLogProps> = ({ 
  data, 
  index, 
  style, 
  height,
  padding,
  onClick,
  isPersonal
 }) => {
  const event = data[index] || {};

  function translateLevel(level: string) {
    switch (level) {
      case 'INFO':
        return 'Информация';
      case 'WARNING':
        return 'Предупреждение';
      case 'DEBUG':
        return 'Отладка';
      default:
        return 'Ошибка';
    }
  }

  return (
    <Card
      height={height}
      style={style}
      padding={padding}
      avatarUrl={event?.avatar}
      onClick={onClick}
      content={
        <div className={styles.info}>
          <div className={styles.chipContainer}>
            <Chip label={translateLevel(event?.level)} className={clsx("text-12", styles.chip)} />
          </div>
          <CustomTypography className={clsx('text-14', styles.infoTitle)}>
            {event?.event}
          </CustomTypography>
          <div className={styles.infoRow}>
            <CustomTypography
              className={clsx('text-12', styles.infoText)}
            >
              Дата и время:
            </CustomTypography>
            <CustomTypography className="text-12">
              {formatDate(event?.date) || 'Loading...'}
            </CustomTypography>
          </div>
          {!isPersonal && <div className={styles.infoRow}>
            <CustomTypography className={clsx('text-12', styles.infoText)}>
              Пользователь:
            </CustomTypography>
            <CustomTypography className="text-12">{event?.user_id || '-'}</CustomTypography>
          </div>}
        </div>
      }
    />
  );
};

export default CardEventLog;
