import React, { MouseEvent, ReactElement } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';
import styles from './MenuControls.module.css';
import clsx from 'clsx';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import { SvgIconProps } from '@mui/material';

interface IMenuControlsProps {
  anchorEl: HTMLButtonElement | null;
  handleClosePopover: () => void;
  isOpen: boolean;
  controls: { 
    icon: React.ElementType<SvgIconProps>, 
    title: string, 
    action: () => void,
    addDivider?: boolean
  }[]
}

const MenuControlsComponent: React.FC<IMenuControlsProps> = ({
  anchorEl,
  handleClosePopover,
  isOpen,
  controls
}) => {
  return (
    <Popover
      classes={{
        paper: clsx(styles.paper),
      }}
      onClose={(e: MouseEvent<ReactElement>) => {
        e.stopPropagation();
        handleClosePopover();
      }}
      anchorEl={anchorEl}
      open={isOpen}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {controls.map((control, index) => (
        <Box key={index} className={styles.buttonWrapper}>
          <CustomPopoverButton
            onClick={control.action}
            startIcon={<CustomMuiIcon Icon={control.icon} className={styles.buttonIcon} customColor={'grey'}/>}
          >
            {control.title}
          </CustomPopoverButton>
          {control?.addDivider && <Box className={styles.divider} />}
        </Box>
      ))}
    </Popover>
  );
};

/**
 * Компонент MenuControlsComponent отображает меню с элементами управления (кнопки).
 * @param anchorEl - Элемент, к которому привязано меню.
 * @param handleClosePopover - Функция для закрытия меню.
 * @param isOpen - Флаг, указывающий, открыто ли меню.
 * @param controls - Массив элементов управления (содержит: иконка, заголовок, действие, разделитель(опционально)).
 */
export const MenuControls = (MenuControlsComponent);