import { createApi, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { queryIdProps, queryProps, querySortParams, responseListItems } from '../types';
import { baseQuery, createQuery, parseResponse } from '../helpers';
import { TShortClient } from './clients';

export type TScope = {
  id: number;
  client_id: string;
  scopes: string[];
  created_at: string;
  client: TShortClient;
};

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['Users'],
  baseQuery: baseQuery('users'),
  endpoints: (builder) => ({
    getUserScopes: builder.query<responseListItems<TScope[]>, queryProps>({
      query: (query) => createQuery<querySortParams>(`${query.userId}/scopes`, 'GET', query),
      transformResponse: (scopes: TScope[], meta: FetchBaseQueryMeta) => parseResponse<TScope[]>(scopes, meta),
    }),
    revokeScopes: builder.mutation<boolean ,queryIdProps>({
      query: (query) => createQuery<{ client_id: string }>(`${query.userId}/scopes`, 'DELETE', {
        client_id: query.clientId,
      }),
    }),
  }),
});

export const {
  useGetUserScopesQuery,
  useLazyGetUserScopesQuery,
  useRevokeScopesMutation
} = usersApi;