import { createApi, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { querySortParams, responseListItems } from '../types';
import { baseQuery, createQuery, parseResponse } from '../helpers';
import { TShortClient } from './clients';

export const catalogApi = createApi({
  reducerPath: 'catalogApi',
  tagTypes: ['catalog'],
  baseQuery: baseQuery('catalog'),
  endpoints: (builder) => ({
    getCatalog: builder.query<responseListItems<TShortClient[]>, querySortParams>({
      query: (query) => createQuery<querySortParams>('', 'GET', query),
      transformResponse: (items: TShortClient[], meta: FetchBaseQueryMeta) =>
        parseResponse<TShortClient[]>(items, meta),
    }),
  }),
});

export const {
  useLazyGetCatalogQuery,
} = catalogApi;