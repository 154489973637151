import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './Modal.module.css';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';

interface IModalInfo {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const ModalInfo: FC<IModalInfo> = ({ isOpen, onClose, children }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={clsx(styles['modal-container'], styles['modal-height'])}>
        <div style={{ display: 'flex' }}>
          <IconButton onClick={onClose} style={{ marginLeft: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </div>
        {children}
      </div>
    </Modal>
  );
};
