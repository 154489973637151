import React, {
  CSSProperties,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './UsersControl.module.css';
import clsx from 'clsx';
import {
  TClient,
  TUser,
  useChangeUsersRoleMutation,
  useGetUserCountQuery,
  useLazyGetUserCountQuery,
  useLazyGetUsersQuery,
} from '../../redux/services/client';
import { setClientPanel, TAppSlice } from '../../redux/appSlice';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Box, MenuItem, Tooltip, debounce } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as ArrowDropDownIcon } from '../../icons/ArrowDropDown.svg';

import { AutoSizer, InfiniteLoader, List, RowMouseEventHandlerParams } from 'react-virtualized';
import { isObjectEmpty } from '../../helpers';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { CLIENT_ID, PROJECT_NAME } from '../../constants';
import { Roles } from '../../enums';
import 'date-fns';
import ru from 'date-fns/locale/ru';
import { format } from 'date-fns';
import { TUserProfile } from '../../redux/userSlice';
import {
  useBlockUsersMutation,
  useDeleteUsersInfoMutation,
  useDeleteUsersMutation,
  useDeleteUsersSessionsMutation,
  useUnblockUsersMutation,
} from '../../redux/services/owner';
import { setNotice } from '../../redux/noticesSlice';
import { CustomSelect } from '../custom/CustomSelect';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { CustomTypography } from '../custom/CustomTypography';
import { BlockUserModal } from '../modal/BlockUserModal';
import { DeleteUserModal } from '../modal/DeleteUserModal';
import DeleteUserSessionsModal from '../modal/DeleteUserSessionsModal';
import { ChangePermissionsModal } from '../modal/ChangePermissionsModal';
import { CustomSearchInput } from '../custom/CustomSearchInput';
import UserItem from './UserItem';

export type Order = 'asc' | 'desc';
export type TColumnNames = 'mainPage' | 'user' | 'role';
export type TUsersControlProps = {
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  clientPanelWidth: TAppSlice['clientPanelWidth'];
  isMobile: TAppSlice['isMobile'];
  owner: TUserProfile;
};

const mapStateToProps = (state: RootState) => ({
  clientPanelWidth: state.app.clientPanelWidth,
  isMobile: state.app.isMobile,
  owner: state.user.userProfile,
  isClientPanelOpen: state.app.isClientPanelOpen,
});

const UsersControlComponent: FC<TUsersControlProps> = ({
  isClientPanelOpen,
  clientPanelWidth,
  isMobile,
  owner,
}) => {
  const [order, setOrder] = useState<Order>('asc');
  const [viewAnchorEl, setViewAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [orderBy, setOrderBy] = useState<TColumnNames>('user');
  const [users, setUsers] = useState<({ user: Partial<TUser>; role: string } | undefined)[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedUserToActions, setSelectedUserToActions] = useState<{
    user: Partial<TUser>;
    role: string;
  } | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverOriginTop, setPopoverOriginTop] = useState(false);
  const [width, setWidth] = useState(0);
  const [animateWidth, setAnimateWidth] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState<
    Roles.ADMIN | Roles.OWNER | Roles.USER | null
  >(null);
  const [changePermissionsModalOpen, setChangePermissionsModalOpen] = useState(false);
  const [checkedUsers, setCheckedUsers] = useState<{ user: TUser; role: string }[]>([]);
  const [uncheckedUsers, setUncheckedUsers] = useState<{ user: TUser; role: string }[]>([]);
  const [applicationsToDelete, setApplicationsToDelete] = useState<TClient[]>([]);
  const [allUsersChecked, setAllUsersChecked] = useState(false);
  const [blockUsersModalOpen, setBlockUsersModalOpen] = useState(false);
  const [deleteUsersModalOpen, setDeleteUsersModalOpen] = useState(false);
  const [deleteUsersSessionsModalOpen, setDeleteUsersSessionsModalOpen] = useState(false);
  const [deleteAppsWithUser, setDeleteAppsWithUser] = useState(false);
  const [searchFieldTooltipOpen, setSearchFieldsTooltipOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedSearchFields, setSelectedSearchFields] = useState<string[]>([]);
  const [deletingSearchFields, setDeletingSearchFields] = useState<string[]>([]);
  const [searchHistoryLoading, setSearchHistoryLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState<{ user: Partial<TUser>; role: string } | null>(
    null,
  );

  const isOpen = Boolean(anchorEl);
  const usersWithoutOwner = users.filter(
    (user) => String(user?.user.id) !== owner.id && user?.user.id !== 1,
  );
  const userToActions = selectedUserToActions?.user;
  const dispatch = useDispatch();
  const rowCount = users.length;
  const columnsCount = Math.min(3, Math.round(width / 350));
  const sidePaddings = 36;
  const lastColumnWidth = 40;
  const columnWidth = (width - sidePaddings - lastColumnWidth) / columnsCount;
  const mounted = useRef(false);
  const loadMoreRowsRef = useRef<null | (() => Promise<void>)>(null);
  const infiniteLoaderRef = useRef<null | InfiniteLoader>(null);
  const loaderWrapperRef = useRef<null | HTMLDivElement>(null);
  const saveParamsRef = useRef<{
    scrollTop?: number;
    searchValue?: string;
    order?: string;
    orderBy?: string;
    selectedSearchFields?: string[];
    checkedUsers?: { user: TUser; role: string }[];
    uncheckedUsers?: { user: TUser; role: string }[];
    allUsersChecked?: boolean;
    selectedUser?: { user: Partial<TUser>; role: string } | null;
  }>({});
  const searchFieldTypeVariants = [
    {
      name: 'id',
      value: 'id',
    },
    {
      name: 'Почта',
      value: 'email',
    },
    {
      name: 'Никнейм',
      value: 'nickname',
    },
    {
      name: 'Имя',
      value: 'given_name',
    },
    {
      name: 'Фамилия',
      value: 'family_name',
    },
    {
      name: 'Номер телефона',
      value: 'phone_number',
    },
    {
      name: 'Логин',
      value: 'login',
    },
  ];

  const user = useSelector((state: RootState) => state.user.userProfile);
  const { data: dataCount, refetch: refetchUsersCount } = useGetUserCountQuery({
    selectedAppId: CLIENT_ID,
    search_string: '',
  });
  const [getUsers, { isFetching: getUsersFetching }] = useLazyGetUsersQuery();
  const [getUserCount, { isFetching: getUsersCountFetching }] = useLazyGetUserCountQuery();
  const [changeUsersRole, { isLoading: changeUsersRoleLoading }] = useChangeUsersRoleMutation();
  const [blockUsers, { isLoading: blockUsersLoading }] = useBlockUsersMutation();
  const [unblockUsers, { isLoading: unblockUsersLoading }] = useUnblockUsersMutation();
  const [deleteUsers, { isLoading: deleteUsersLoading }] = useDeleteUsersMutation();

  const [getDeleteUsersInfo, { data: deleteUsersInfoData, isLoading: deleteUsersInfoLoading }] =
    useDeleteUsersInfoMutation();
  const [deleteUsersSessions, { isLoading: deleteUsersSessionsLoading }] =
    useDeleteUsersSessionsMutation();
  const [selectedSearchFieldsBeforeChange, setSelectedSearchFieldsBeforeChange] = useState<
    string[]
  >([]);
  const disableButtons =
    blockUsersLoading || unblockUsersLoading || deleteUsersLoading || changeUsersRoleLoading;
  const actionPopoverDisabled =
    getUsersFetching ||
    changeUsersRoleLoading ||
    blockUsersLoading ||
    unblockUsersLoading ||
    deleteUsersLoading ||
    deleteUsersInfoLoading ||
    getUsersCountFetching;
  const navigate = useNavigate();

  useEffectOnce(() => {
    if (users.length) {
      const savedParams = JSON.parse(sessionStorage.getItem('savedParams') || 'null');
      if (savedParams) {
        setCheckedUsers(savedParams.checkedUsers || []);
        setUncheckedUsers(savedParams.uncheckedUsers || []);
        if (savedParams.allUsersChecked || savedParams.uncheckedUsers) {
          setAllUsersChecked(savedParams.allUsersChecked);
        }
        if (savedParams.selectedUser) {
          setSelectedUser(savedParams.selectedUser);
        }
        if (savedParams.scrollTop) {
          const table = loaderWrapperRef.current?.querySelector('.users-table__grid');
          table?.scrollTo({
            top: savedParams.scrollTop,
          });
        }
      }
      return true;
    }
    return false;
  }, [users]);

  useEffect(() => {
    if (!mounted.current) {
      setAnimateWidth(false);
      mounted.current = true;
    } else {
      setAnimateWidth(true);
      return () => setAnimateWidth(false);
    }
  }, [isClientPanelOpen]);

  useEffect(() => {
    if (users[0] && !selectedUser) setSelectedUser(users[0]);
  }, [users]);

  useEffect(() => {
    const setUsersOnStart = async () => {
      setSearchValue('');
      const { data: countData } = await getUserCount({
        selectedAppId: CLIENT_ID,
        search_string: '',
      });

      setUsers(new Array(Number(countData?.userCount)).fill(undefined));
    };
    setUsersOnStart();

    const handleUnload = () => {
      sessionStorage.setItem(
        'savedParams',
        JSON.stringify({
          ...saveParamsRef.current,
        }),
      );
    };

    window.addEventListener('beforeunload', handleUnload);
    return () => {
      handleUnload();
      window.removeEventListener('beforeunload', handleUnload);
      setUsers([]);
    };
  }, []);

  useEffect(() => {
    if (users.every((user) => user === undefined))
      infiniteLoaderRef.current?.resetLoadMoreRowsCache(true);
  }, [users]);

  useEffect(() => {
    clearUsers();
  }, [order, orderBy]);

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth - 31 - +!isMobile * (clientPanelWidth * +!isClientPanelOpen));
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [isClientPanelOpen, clientPanelWidth, isMobile]);

  useEffect(() => {
    if (
      !selectOpen &&
      searchValue &&
      !(
        selectedSearchFieldsBeforeChange.every((selectedBefore) =>
          selectedSearchFields.some((selected) => selected === selectedBefore),
        ) &&
        selectedSearchFields.every((selected) =>
          selectedSearchFieldsBeforeChange.some((selectedBefore) => selectedBefore === selected),
        )
      )
    )
      onSearch(searchValue);
  }, [selectOpen]);

  useEffect(() => {
    if (!getUsersFetching) {
      loadMoreRowsRef.current?.();
      loadMoreRowsRef.current = null;
    }
  }, [getUsersFetching]);

  useEffect(() => {
    saveParamsRef.current = {
      ...saveParamsRef.current,
      searchValue,
      order,
      orderBy,
      selectedSearchFields,
      checkedUsers,
      allUsersChecked,
      uncheckedUsers,
      selectedUser,
    };
  }, [
    searchValue,
    order,
    orderBy,
    selectedSearchFields,
    checkedUsers,
    allUsersChecked,
    uncheckedUsers,
    selectedUser,
  ]);

  const setIsPanelOpen = (isOpen: boolean) => dispatch(setClientPanel(isOpen));

  const setPanelView = (e: MouseEvent<HTMLLabelElement | SVGSVGElement>, isOpen: boolean) => {
    e.stopPropagation();
    setIsPanelOpen(isOpen);
    setViewAnchorEl(null);
  };

  const onSearch = async (searchString: string) => {
    setAllUsersChecked(false);
    setUncheckedUsers([]);
    setCheckedUsers([]);
    const { data: countData } = await getUserCount({
      selectedAppId: CLIENT_ID,
      search_string: searchString,
      search_filter: !selectedSearchFields.length || !searchString ? ['all'] : selectedSearchFields,
    });

    if (countData !== undefined) {
      const { userCount } = countData;
      setUsers(new Array(Number(userCount)).fill(undefined));
    }
  };

  const clearUsers = async (refreshUserCount?: boolean) => {
    setCheckedUsers([]);
    setUncheckedUsers([]);
    setAllUsersChecked(false);
    setSelectedUser(null);
    if (refreshUserCount) {
      const count = await getUserCount({
        selectedAppId: CLIENT_ID,
        search_string: searchValue,
      }).unwrap();

      if (count !== undefined) {
        setUsers(new Array(Number(count.userCount)).fill(undefined));
      }
    } else {
      setUsers((users) => users.map(() => undefined));
    }
  };

  const onSearchDebounce = useCallback(debounce(onSearch, 700), [
    CLIENT_ID,
    order,
    orderBy,
    allUsersChecked,
    selectedSearchFields,
  ]);

  const rowGetter = ({ index }: { index: number }) => {
    return users?.[index] || {};
  };

  const onRowClick = (rowInfo: RowMouseEventHandlerParams) => {
    if (!isObjectEmpty(rowInfo.rowData) && rowInfo.rowData.user.id !== owner.id)
      setSelectedUser(rowInfo.rowData);
  };

  const isRowLoaded = ({ index }: { index: number }) => !!users[index];

  const loadMoreRows = async ({
    startIndex,
    stopIndex,
    searchString,
    ignoreUsersLoading,
  }: {
    startIndex: number;
    stopIndex: number;
    searchString?: string;
    ignoreUsersLoading?: boolean;
  }) => {
    const searchValueToSend = searchString === undefined ? searchValue : searchString;

    if (!getUsersFetching || ignoreUsersLoading) {
      const { data } = await getUsers({
        client_id: CLIENT_ID,
        number_of_records: String(stopIndex - startIndex + 1),
        number_of_skip: String(startIndex),
        search_filter:
          !selectedSearchFields.length || !searchValueToSend ? ['all'] : selectedSearchFields,
        sort_by: orderBy === 'user' ? 'nickname' : orderBy,
        sort_direction: order,
        search_string: searchValueToSend,
      });

      setUsers((users) =>
        users.map((user, index) => {
          if (index < startIndex || index > stopIndex) return user;
          return data?.[index - startIndex];
        }),
      );
    } else {
      loadMoreRowsRef.current = () =>
        loadMoreRows({ startIndex, stopIndex, searchString, ignoreUsersLoading: true });
    }
  };

  const closeChangePermissionModal = () => {
    setSelectedPermission(null);
    setChangePermissionsModalOpen(false);
  };

  const getRowClassName = ({ index }: { index: number }) => {
    const userId = (rowGetter?.({ index }) as { user: TUser }).user?.id;

    return clsx(styles.row, {
      [styles['content-row']]: index !== -1,
      [styles['header-row']]: index === -1,
      [styles['selected-row']]:
        ((selectedUser && selectedUser.user.id === userId) ||
          !!checkedUsers.find((user) => user.user.id === userId) ||
          (uncheckedUsers.length &&
            !uncheckedUsers.find((user) => user.user.id === userId) &&
            String(userId) !== owner.id &&
            userId !== 1) ||
          (allUsersChecked && String(userId) !== owner.id && userId !== 1)) &&
        index !== -1,
      [styles['selected-checked-row']]:
        selectedUser &&
        selectedUser.user.id === userId &&
        !!checkedUsers.find((user) => user.user.id === userId),
    });
  };

  const handleRequestSort = (property: TColumnNames) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenPopover = (
    event: MouseEvent<HTMLButtonElement>,
    user: { user: Partial<TUser>; role: string },
  ) => {
    event.stopPropagation();
    if (event.clientY + 180 > document.body.clientHeight) setPopoverOriginTop(true);
    else setPopoverOriginTop(false);
    setSelectedUserToActions(user);
    setAnchorEl(event.currentTarget);
  };

  const handleCheckUser = (user: { user: TUser; role: string }) => {
    if (allUsersChecked || uncheckedUsers.length) {
      if (uncheckedUsers.find((uncheckedUser) => uncheckedUser.user.id === user?.user?.id)) {
        if (uncheckedUsers.length === 1) setAllUsersChecked(true);
        setUncheckedUsers(
          uncheckedUsers.filter((uncheckedUser) => uncheckedUser.user.id !== user?.user?.id),
        );
      } else {
        setUncheckedUsers((users) => [...users, user]);
        setAllUsersChecked(false);
      }

      return;
    }

    if (checkedUsers.find((checkedUser) => checkedUser.user.id === user?.user?.id)) {
      setCheckedUsers(checkedUsers.filter((checkedUser) => checkedUser.user.id !== user?.user?.id));
    } else {
      setCheckedUsers((users) => [...users, user]);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleSaveRole = async () => {
    try {
      if (!selectedPermission || !userToActions?.id) return;
      handleClosePopover();
      if (
        CLIENT_ID &&
        userToActions?.id &&
        users.find((user) => userToActions.id === user?.user.id)?.role !== selectedPermission
      ) {
        await changeUsersRole({
          checked_ids: [userToActions.id],
          role: selectedPermission,
          client_id: CLIENT_ID,
        }).unwrap();

        clearUsers();
        setChangePermissionsModalOpen(false);

        setSelectedPermission(null);

        dispatch(
          setNotice({
            id: Math.random(),
            isRead: false,
            message: `Права ${userToActions?.nickname} в ${PROJECT_NAME} изменены.`,
            timestamp: new Date().toString(),
            avatar: userToActions?.picture,
          }),
        );

        await onSearchDebounce(searchValue);
      }
    } catch (e) {
      console.log('handleSaveRole error:', e);
    }
  };

  const handleDeleteUsersSessions = async () => {
    try {
      if (!userToActions?.id) return;
      handleClosePopover();
      await deleteUsersSessions({
        checked_ids: [userToActions.id],
      }).unwrap();

      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Сеансы ${userToActions.nickname} завершены.`,
          timestamp: new Date().toString(),
          avatar: userToActions.picture,
        }),
      );

      setDeleteUsersSessionsModalOpen(false);
    } catch (e) {
      console.log('handleDeleteUsersSessions error:', e);
    }
  };

  const handleBlockUser = async () => {
    try {
      if (!userToActions?.id) return;
      handleClosePopover();
      const isBlock = !userToActions.blocked;
      if (isBlock)
        await blockUsers({
          checked_ids: [userToActions.id],
        }).unwrap();
      else
        await unblockUsers({
          checked_ids: [userToActions.id],
        }).unwrap();

      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Пользователь ${userToActions?.nickname} ${
            isBlock ? 'заблокирован' : 'разблокирован'
          } в ${PROJECT_NAME}.`,
          timestamp: new Date().toString(),
          avatar: userToActions?.picture,
        }),
      );

      setBlockUsersModalOpen(false);
      clearUsers();
    } catch (e) {
      console.log('handleBlockUser error:', e);
    }
  };

  const getUserStatus = (user?: TUser) => {
    if (user?.blocked)
      return (
        <CustomTypography className={clsx('text-14')} color="error">
          Заблокированный
        </CustomTypography>
      );
    const deleted = user?.deleted;
    if (deleted) {
      const deleteDate = new Date(deleted);
      deleteDate.setDate(deleteDate.getDate() + 30);

      return (
        <CustomTypography className={clsx('text-14')} color="grey">
          Будет удален {format(deleteDate, 'dd MMMM y г. в HH:mm', { locale: ru })}
        </CustomTypography>
      );
    }

    return <CustomTypography className="text-14">Активный</CustomTypography>;
  };

  const radioButtonSelected = (role: Roles.USER | Roles.ADMIN | Roles.OWNER) => {
    if (selectedPermission) return selectedPermission === role;
    return selectedUserToActions?.role === role;
  };

  const handleDeleteUser = async () => {
    try {
      if (!userToActions?.id) return;
      await deleteUsers({
        delete_apps_with_user: deleteAppsWithUser,
        apps_ids: deleteAppsWithUser
          ? deleteUsersInfoData?.apps.map(({ client }) => client.client_id)
          : deleteUsersInfoData?.apps
              .filter((app) => !app.onlyEditor)
              .map(({ client }) => client.client_id),
        checked_ids: [userToActions.id],
      }).unwrap();

      const id: string =
        userToActions.nickname || userToActions.login || userToActions.id.toString();

      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Пользователь ${id} удален в ${PROJECT_NAME}.`,
          timestamp: new Date().toString(),
          avatar: userToActions.picture,
        }),
      );
      clearUsers(true);

      refetchUsersCount();

      setDeleteUsersModalOpen(false);
    } catch (e) {
      console.log('handleDeleteUsers error', e);
    }
  };

  const handleUpdateUsersList = async () => {
    const { data: countData } = await getUserCount({
      selectedAppId: CLIENT_ID,
      search_string: '',
    });

    setUsers(new Array(Number(countData?.userCount)).fill(undefined));
  };

  const handleDeleteButton = async () => {
    try {
      if (!userToActions?.id) return;
      if (String(userToActions.id) === owner.id) return navigate('profile/delete');
      const deleteUsersInfo = await getDeleteUsersInfo({
        checked_ids: [userToActions.id],
      }).unwrap();
      setApplicationsToDelete(
        deleteUsersInfo.apps.filter((app) => app.onlyEditor).map((app) => app.client),
      );
      setDeleteUsersModalOpen(true);
    } catch (e) {
      console.log('handleDeleteButton error', e);
    }
  };

  const rowRenderer = ({
    key,
    index,
    style,
  }: {
    key: string;
    index: number;
    style: CSSProperties;
  }) => {
    const user = users[index];
    const handleEventClick = (e: MouseEvent<HTMLDivElement>) => {
      if (user) {
        navigate(`/applications/user/${CLIENT_ID}/${user.user.id}`);
      }
    };

    return (
      <Box key={key} style={style} className={styles.event} onClick={(e) => handleEventClick(e)}>
        {!user ? (
          <div className={styles['content-container']}>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="25%" />
            <Skeleton variant="text" width="25%" />
          </div>
        ) : (
          <UserItem
            user={user.user}
            role={user.role}
            setChangePermissionsModalOpen={() => setChangePermissionsModalOpen(true)}
            setDeleteUsersSessionsModalOpen={() => setDeleteUsersSessionsModalOpen(true)}
            setBlockUsersModalOpen={() => setBlockUsersModalOpen(true)}
            handleDeleteButton={handleDeleteButton}
            actionPopoverDisabled={actionPopoverDisabled}
            setUserToActions={() => setSelectedUserToActions(user)}
          />
        )}
      </Box>
    );
  };

  return (
    <Box className={'wrapper'} sx={{ overflow: 'hidden' }}>
      <Box className={'content'}>
        <div className={styles['bar']}>
          <CustomSearchInput
            onSearch={onSearch}
            searchParams={{
              order: order,
              orderBy: orderBy,
              CLIENT_ID: CLIENT_ID,
              allUsersChecked: allUsersChecked,
              selectedSearchFields: selectedSearchFields,
            }}
            setSearchValue={setSearchValue}
          />

          <div className={styles['action-section']}>
            <Tooltip
              arrow
              open={searchFieldTooltipOpen && !selectOpen}
              title={selectedSearchFields
                .map(
                  (value) =>
                    searchFieldTypeVariants.find((variant) => variant.value === value)?.name,
                )
                .join(', ')}
              classes={{
                tooltip: styles['input-tooltip'],
                arrow: styles['input-tooltip-arrow'],
              }}
            >
              <CustomSelect
                open={selectOpen}
                onClick={() => {
                  if (!selectOpen) setSelectedSearchFieldsBeforeChange(selectedSearchFields);
                  setSelectOpen(!selectOpen);
                }}
                onClose={() => {
                  setSearchFieldsTooltipOpen(false);
                  setSelectOpen(false);
                }}
                onMouseOver={(e) => {
                  if (e.target === e.currentTarget) setSearchFieldsTooltipOpen(true);
                }}
                onMouseLeave={() => setSearchFieldsTooltipOpen(false)}
                IconComponent={() => (
                  <>
                    {!!selectedSearchFields.length && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          if (searchValue) {
                            setSearchHistoryLoading(true);
                            onSearchDebounce(searchValue);
                          }
                          setSelectedSearchFields([]);
                        }}
                        className={styles['delete-icon-button']}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                    <ArrowDropDownIcon
                      style={{
                        width: 40,
                        transform: selectOpen ? 'rotate(180deg)' : '',
                        cursor: 'pointer',
                      }}
                    />
                  </>
                )}
                multiple
                className={styles.select}
                value={selectedSearchFields}
                displayEmpty={true}
                renderValue={(values) => (
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {values.length
                      ? values
                          .map(
                            (value) =>
                              searchFieldTypeVariants.find((variant) => variant.value === value)
                                ?.name,
                          )
                          .join(', ')
                      : 'Все'}
                  </div>
                )}
                onChange={(e) => {
                  const targetValue = e.target.value as string[];
                  setSelectedSearchFields(targetValue);
                }}
              >
                {searchFieldTypeVariants.map((variant) => (
                  <MenuItem
                    className="custom-select"
                    key={variant.value}
                    value={variant.value}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {variant.name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Tooltip>
            <Tooltip placement="bottom-end" arrow title="Создать пользователя">
              <Button
                onClick={() => navigate('create')}
                variant="custom"
                color="secondary"
                className={styles['create-user-button']}
                startIcon={<PersonAddAltOutlinedIcon />}
              />
            </Tooltip>
          </div>
        </div>
        <div ref={loaderWrapperRef} className={clsx(styles['table-wrapper'])}>
          <InfiniteLoader
            ref={infiniteLoaderRef}
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={rowCount}
          >
            {({ onRowsRendered, registerChild }) => {
              return (
                <AutoSizer>
                  {({ height, width }) => {
                    return (
                      <>
                        <List
                          height={height}
                          width={width}
                          rowHeight={isMobile ? 192 : 92}
                          rowCount={rowCount}
                          rowRenderer={rowRenderer}
                          onRowsRendered={onRowsRendered}
                          ref={registerChild}
                        />
                      </>
                    );
                  }}
                </AutoSizer>
              );
            }}
          </InfiniteLoader>
        </div>
        {changePermissionsModalOpen && (
          <ChangePermissionsModal
            isOpen={changePermissionsModalOpen}
            setIsOpen={closeChangePermissionModal}
            userToActions={userToActions}
            disableButtons={disableButtons}
            radioButtonSelected={radioButtonSelected}
            setSelectedPermission={setSelectedPermission}
            handleSaveRole={handleSaveRole}
          />
        )}
        {deleteUsersSessionsModalOpen && (
          <DeleteUserSessionsModal
            isOpen={deleteUsersSessionsModalOpen}
            setIsOpen={setDeleteUsersSessionsModalOpen}
            disableButtons={disableButtons}
            deleteUsersSessionsLoading={deleteUsersSessionsLoading}
            userToActions={userToActions}
            handleDeleteUsersSessions={handleDeleteUsersSessions}
          />
        )}
        {deleteUsersModalOpen && (
          <DeleteUserModal
            isOpen={deleteUsersModalOpen}
            setIsOpen={setDeleteUsersModalOpen}
            handleDeleteUser={handleDeleteUser}
            userToActions={userToActions}
            applicationsToDelete={applicationsToDelete}
            deleteUsersInfoData={deleteUsersInfoData}
            disableButtons={disableButtons}
            deleteAppsWithUser={deleteAppsWithUser}
            setDeleteAppsWithUser={setDeleteAppsWithUser}
          />
        )}
        {blockUsersModalOpen && (
          <BlockUserModal
            isOpen={blockUsersModalOpen}
            setIsOpen={setBlockUsersModalOpen}
            handleBlockUser={handleBlockUser}
            haveActiveUsers={!userToActions?.blocked}
            disableButtons={disableButtons}
          />
        )}
      </Box>
    </Box>
  );
};

export const UsersControl = connect(mapStateToProps)(UsersControlComponent);
