import { Popover } from '@mui/material';
import React, { MouseEvent, ReactElement, useState } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { CLIENT_ID, PROJECT_NAME } from 'src/constants';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import { ReactComponent as BasketIcon } from '../../icons/Basket.svg';
import { ReactComponent as ExportIcon } from '../../icons/Export.svg';
import { ReactComponent as LockIcon } from '../../icons/Lock.svg';
import { ReactComponent as OwnerIcon } from '../../icons/Owner.svg';
import { ReactComponent as QuitIcon } from '../../icons/Quit.svg';
import { ReactComponent as UnlockIcon } from '../../icons/Unlock.svg';
import { CustomPopoverButton } from '../custom/CustomPopoverButton';
import styles from './UserActionsPopover.module.css';
import clsx from 'clsx';
import { exportToJson } from 'src/helpers';
import { TUser } from 'src/redux/services/client';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/rootReducer';

type TUserActionsPopover = {
  popoverOriginTop: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClosePopover: () => void;
  isOpen: boolean;
  userToActions: {
    user: Partial<TUser>;
    role: string;
  };
  setChangePermissionsModalOpen: (isOpen: boolean) => void;
  setDeleteUsersSessionsModalOpen: (isOpen: boolean) => void;
  setBlockUsersModalOpen: (isOpen: boolean) => void;
  handleDeleteButton: () => Promise<void>;
};

const UserActionsPopover = ({
  popoverOriginTop,
  anchorEl,
  handleClosePopover,
  isOpen,
  userToActions,
  setChangePermissionsModalOpen,
  setDeleteUsersSessionsModalOpen,
  setBlockUsersModalOpen,
  handleDeleteButton,
}: TUserActionsPopover) => {
  const owner = useSelector((state: RootState) => state.user.userProfile);

  return (
    <div>
      <Popover
        classes={{
          paper: clsx(styles.paper, styles['popover-paper'], {
            [styles['horizontal-direction-top']]: popoverOriginTop,
          }),
        }}
        onClose={(e: MouseEvent<ReactElement>) => {
          e.stopPropagation();
          handleClosePopover();
        }}
        anchorEl={anchorEl}
        open={isOpen}
        anchorOrigin={{
          vertical: popoverOriginTop ? 'top' : 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: popoverOriginTop ? 'bottom' : 'top',
          horizontal: 'right',
        }}
      >
        {!userToActions?.user.deleted && (
          <CustomPopoverButton
            className={styles['custom-popover-button']}
            onClick={(e) => {
              e.stopPropagation();
              if (userToActions) exportToJson(userToActions, 'profile.json');
            }}
            startIcon={<ExportIcon className={styles['action-button-icon']} />}
          >
            Скачать данные профиля
          </CustomPopoverButton>
        )}
        <Link
          className={styles['custom-popover-button']}
          style={{
            display: 'block',
            width: '100%',
            textDecoration: 'none',
          }}
          to={
            owner?.id === String(userToActions?.user.id)
              ? '/profile'
              : `/applications/user/${CLIENT_ID}/${userToActions?.user?.id}`
          }
        >
          <CustomPopoverButton startIcon={<AvatarIcon className={styles['action-button-icon']} />}>
            Посмотреть профиль
          </CustomPopoverButton>
        </Link>
        {String(userToActions?.user.id) !== owner.id && userToActions?.user.id !== 1 && (
          <CustomPopoverButton
            className={styles['custom-popover-button']}
            onClick={(e) => {
              e.stopPropagation();
              setChangePermissionsModalOpen(true);
            }}
            startIcon={<OwnerIcon className={styles['action-button-icon']} />}
          >
            Изменить права
          </CustomPopoverButton>
        )}
        {!userToActions?.user.deleted && !userToActions?.user.blocked && (
          <CustomPopoverButton
            className={styles['custom-popover-button']}
            onClick={(e) => {
              e.stopPropagation();
              setDeleteUsersSessionsModalOpen(true);
            }}
            startIcon={<QuitIcon className={styles['action-button-icon']} />}
          >
            Завершить все сеансы
          </CustomPopoverButton>
        )}
        {String(userToActions?.user.id) !== owner.id && userToActions?.user.id !== 1 && (
          <CustomPopoverButton
            className={styles['custom-popover-button']}
            onClick={(e) => {
              e.stopPropagation();
              setBlockUsersModalOpen(true);
            }}
            startIcon={
              userToActions?.user.blocked ? (
                <UnlockIcon className={styles['action-button-icon']} />
              ) : (
                <LockIcon className={styles['action-button-icon']} />
              )
            }
          >
            {userToActions?.user.blocked ? 'Разблокировать' : 'Заблокировать'} в {PROJECT_NAME}
          </CustomPopoverButton>
        )}
        {String(userToActions?.user.id) !== owner.id && userToActions?.user.id !== 1 && (
          <CustomPopoverButton
            className={styles['custom-popover-button']}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteButton();
              handleClosePopover();
            }}
            startIcon={<BasketIcon className={styles['action-button-icon']} />}
          >
            Удалить аккаунт
          </CustomPopoverButton>
        )}
      </Popover>
    </div>
  );
};

export default UserActionsPopover;
