import { TWidgetColors } from './EditApplication';
import * as yup from 'yup';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import { ReactComponent as PhoneIcon } from '../../icons/Phone.svg';
import IconButton from '@mui/material/IconButton';
import { IconWrapper } from '../IconWrapper';
import { getProviderTitleByType } from '../../helpers';
import Skeleton from '@mui/material/Skeleton';
import React, { FC, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import styles from './EditApplication.module.css';
import Button from '@mui/material/Button';
import { SubmitHandler, useForm, FormProvider, Controller } from 'react-hook-form';
import {
  TClient,
  useUpdateApplicationMutation,
  clientApi,
  useGetApplicationByIdQuery,
} from '../../redux/services/client';
import { useDispatch, useSelector } from 'react-redux';
import { setApplicationFormChanged } from '../../redux/appSlice';
import { CustomRadioButton } from '../CustomRadioButton';
import { EditProviders } from '../Providers/EditProviders';
import {
  TOauthProvider,
  TMiscProvider,
  providerApi,
  useGetProvidersQuery,
  useDeactivateProvidersMutation,
  MiscProviderType,
  EGetProviderAction,
} from '../../redux/services/provider';
import { isObjectEmpty } from '../../helpers';
import { EditApplicationHeader, TProviderItem } from './EditApplicationHeader';
import { EditApplicationFooter } from './EditApplicationFooter';
import { yupResolver } from '@hookform/resolvers/yup';
import { RootState } from '../../redux/store';
import { ProviderScope, useGetRulesProvidersQuery } from '../../redux/services/settings';
import { setNotice } from '../../redux/noticesSlice';
import { useParams } from 'react-router-dom-v5-compat';
import { EGrantTypes } from './CreateApplication';
import { Accordion, AccordionDetails, AccordionSummary, ListItem } from '@mui/material';
import {
  settingsApi,
  TProfileField,
  TRule,
  useEditSettingsMutation,
  useGetProfileFieldsQuery,
  useGetSettingsQuery,
} from '../../redux/services/settings';
import { BACKEND_URL, CLIENT_ID, PROJECT_NAME } from '../../constants';
import { ProfileField } from './ProfileField';
import { EditProfileField } from './EditProfileField';
import { EditRuleValidations } from './EditRuleValidations';
import { useGetUserQuery } from '../../redux/services/user';
import { ModalWithAction } from '../modal/ModalWithAction';
import { CustomTypography } from '../custom/CustomTypography';
import { CustomIcon } from '../custom/CustomIcon';
import { EditEmailTemplates } from '../EditEmailTemplates';
import { IEmailTemplate } from '../EmailTemplate';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import LicenseItem from './LicenseItem';
import { ModalAddLicense } from '../modal/ModalAddLicense';
import { useGetLicensesQuery } from 'src/redux/services/licenses';
import { EditSentry } from '../EditSentry';

export const editProfileSchema = (rules: TRule[]): yup.AnyObjectSchema => {
  if (!rules) return yup.object();
  return generateValidationSchema(rules.filter((rule) => rule.field_name !== 'password'));
};

export const generateValidationSchema = (rules: TRule[]) => {
  const schemaFields = rules.reduce((schema, field) => {
    if (!field.active) return schema;

    let fieldValidations = yup.string().nullable().notRequired();

    // Применение всех активных правил валидации для поля
    field.validations.forEach((validation) => {
      if (validation.active) {
        fieldValidations = fieldValidations.test({
          name: `${field.field_name}-regex`,
          message: validation.error,
          test: (value) => {
            if (field.required && (value === null || value === undefined || value === '')) {
              return false; // пропускаем проверку, если поле обязательное и пустое
            }
            // Применяем regex только если поле не пустое
            if (value === null || value === undefined || value === '') {
              return true; // пропускаем проверку, если поле пустое
            }
            const result = new RegExp(validation.regex).test(value);
            return result;
          },
        });
      }
    });

    return {
      ...schema,
      [field.field_name]: fieldValidations,
    };
  }, {});

  return yup.object().shape(schemaFields).required();
};

export enum RegistrationPolicyVariants {
  allowed = 'allowed',
  allowed_autoregistration_only = 'allowed_autoregistration_only',
  disabled = 'disabled',
}

export type TEditSettingsInputs = {
  name: string;
  description: string;
  domain: string;
  redirect_uris: {
    name: string;
    value: string;
  }[];
  post_logout_redirect_uris: {
    name: string;
    value: string;
  }[];
  avatar: File | string | null;
  widget_colors: TWidgetColors;
  show_avatar_in_widget: boolean;
  widget_title: string;
  registration_policy: string;
  allow_everyone_to_create_applications: boolean;
  authorize_only_admins: boolean;
  default_public_profile_claims_oauth: string;
  default_public_profile_claims_gravatar: string;
  phone_provider_enabled: boolean;
};

const schema = yup
  .object({
    widget_colors: yup
      .object({
        button_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        font_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        link_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
      })
      .required(),
    registration_policy: yup.string().required('Обязательно поле'),
    allow_everyone_to_create_applications: yup.boolean().required('Обязательно поле'),
    authorize_only_admins: yup.boolean().required('Обязательно поле'),
  })
  .required();

type TEditSettings = {
  selectedClient: TClient;
  userId: string;
};

export const EditSettings: FC<TEditSettings> = ({ selectedClient, userId }) => {
  const dispatch = useDispatch();
  const savedCallback = useRef<() => void>();
  const { openedFromMenu } = useParams<{ openedFromMenu: string }>();
  const applicationFormChanged = useSelector(
    (state: RootState) => state.app.applicationFormChanged,
  );

  const { data: dataSettings } = useGetSettingsQuery();
  const { data: rules = [] } = useGetRulesProvidersQuery();
  const { data: profileFields } = useGetProfileFieldsQuery();
  const { data: licenseList } = useGetLicensesQuery();
  const { data: providers = [], isLoading: providersLoading } = useGetProvidersQuery({
    client_id: selectedClient.client_id,
    onlyActive: false,
    action: EGetProviderAction.change,
  });
  const { isFetching: getApplicationFetching } = useGetApplicationByIdQuery(
    { user_id: userId || '', client_id: selectedClient.client_id || '' },
    {
      skip: !userId || !selectedClient.client_id,
    },
  );
  // #807
  // const { data: availableUsersCount } = useGetAvailableUsersCountQuery();

  const [deactivateProvider] = useDeactivateProvidersMutation();
  const [editSettings, editSettingsResult] = useEditSettingsMutation();
  const [updateApplication, { isLoading: updateApplicationLoading }] =
    useUpdateApplicationMutation();

  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [coverSrc, setCoverSrc] = useState<string | null>(null);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [providerModalOpen, setProviderModalOpen] = useState(false);
  const [addLicenseModalOpen, setAddLicenseModalOpen] = useState(false);
  const [isEmailTemplatesModalOpen, setEmailTemplatesModalOpen] = useState(false);
  const [isSentryModalOpen, setSentryModalOpen] = useState(false);
  const [savePasswordPolicyModalOpen, setSavePasswordPolicyModalOpen] = useState(false);
  const [claims, setClaims] = useState<{ gravatar: string[]; oauth: string[] }>({
    gravatar: [],
    oauth: [],
  });
  // TODO: для phoneProvider принимать и передавать только необходимые параметры: issuer, external_client_id, external_client_secret, phoneProvider.id
  // TODO: для emailProvider тоже принимать только необходимые параметры
  const [phoneProvider, setPhoneProvider] = useState<TMiscProvider | undefined>(undefined);
  const [emailProvider, setEmailProvider] = useState<TMiscProvider | undefined>(undefined);
  const [selectedProvider, setSelectedProvider] = useState<TProviderItem | undefined>(undefined);
  const [selectedProviderType, setSelectedProviderType] = useState<ProviderScope | undefined>(
    undefined,
  );
  const [selectedProfileField, setSelectedProfileField] = useState<TProfileField | undefined>(
    undefined,
  );
  const { refetch: getUser } = useGetUserQuery();

  const listLoginProviders: TProviderItem[] = [];
  const listTrustedProviders: TProviderItem[] = [];

  const emailTemplates: IEmailTemplate[] = dataSettings?.email_templates || [];
  const sentry = dataSettings?.sentry;

  for (const provider of providers) {
    const rule = rules.find((rule) => rule.type === provider.type);
    if (!rule) {
      continue;
    }

    if (!rule.allowedScopes) {
      continue;
    }

    if (
      rule.allowedScopes.includes(ProviderScope.login) ||
      rule.allowedScopes.includes(ProviderScope.otp) ||
      rule.allowedScopes.includes(ProviderScope.trusted)
    ) {
      listLoginProviders.push({
        rule,
        provider,
      });
    }

    if (rule.allowedScopes.includes(ProviderScope.trusted)) {
      listTrustedProviders.push({
        rule,
        provider,
      });
    }
  }

  const generalProfileFields = profileFields?.filter((item) => item.type === 'general');
  const customProfileFields = profileFields?.filter((item) => item.type === 'custom');

  const listGeneralProfileFields = generalProfileFields?.map((item) => {
    return (
      <ProfileField
        onClick={() => {
          setSelectedProfileField(item);
          setProfileModalOpen(true);
        }}
        key={item.field}
        profile={item}
      />
    );
  });
  const listCustomProfileFields = customProfileFields?.map((item) => {
    return (
      <ProfileField
        onClick={() => {
          setSelectedProfileField(item);
          setProfileModalOpen(true);
        }}
        key={item.field}
        profile={item}
        deleted={true}
      />
    );
  });

  useEffect(() => {
    if (dataSettings)
      setClaims({
        oauth: dataSettings.default_public_profile_claims_oauth
          ? dataSettings.default_public_profile_claims_oauth.split(' ')
          : [],
        gravatar: dataSettings.default_public_profile_claims_gravatar
          ? dataSettings.default_public_profile_claims_gravatar.split(' ')
          : [],
      });
  }, [dataSettings]);

  useEffect(() => {
    setValue('default_public_profile_claims_oauth', claims.oauth.join(' '), { shouldDirty: true });
    setValue('default_public_profile_claims_gravatar', claims.gravatar.join(' '), {
      shouldDirty: true,
    });
  }, [claims]);

  useEffect(() => {
    if (editSettingsResult.isSuccess) {
      dispatch(
        setNotice({
          id: Math.random(),
          isRead: false,
          message: `Настройки личного кабинета сохранены.`,
          timestamp: new Date().toString(),
        }),
      );
    }
  }, [editSettingsResult]);

  useEffect(() => {
    getUser();
  }, [profileFields]);

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
      setClaims({
        gravatar: [],
        oauth: [],
      });
    };
  }, []);

  useEffect(() => {
    if (providers.length) {
      if (selectedProfileField?.field === 'phone_number') {
        const phoneProvider = providers.find(
          (provider) => provider.type === MiscProviderType.PHONE,
        );

        if (phoneProvider && phoneProvider.type === MiscProviderType.PHONE) {
          setPhoneProvider(phoneProvider);
        } else {
          setPhoneProvider(undefined);
        }
      }

      if (selectedProfileField?.field === 'email') {
        const emailProvider = providers.find(
          (provider) => provider.type === MiscProviderType.EMAIL,
        );

        if (emailProvider && emailProvider.type === MiscProviderType.EMAIL) {
          setEmailProvider(emailProvider);
        } else {
          setEmailProvider(undefined);
        }
      }
    }
  }, [selectedProfileField, providers]);

  const methods = useForm<TEditSettingsInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...selectedClient,
      description: selectedClient.description || '',
      redirect_uris: selectedClient.redirect_uris.map((uri) => ({ value: uri })) || [{ value: '' }],
      post_logout_redirect_uris: selectedClient.post_logout_redirect_uris.map((uri) => ({
        value: uri,
      })) || [{ value: '' }],
      avatar: selectedClient.avatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    control,
    resetField,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
    setError,
  } = methods;

  useEffect(() => {
    if (!dataSettings) return;

    const fieldsToReset = [
      'authorize_only_admins',
      'allow_everyone_to_create_applications',
      'registration_policy',
    ] as const;

    fieldsToReset.forEach((fieldName) => {
      const fieldValue = dataSettings[fieldName];
      if (fieldValue !== undefined) {
        resetField(fieldName, { defaultValue: fieldValue });
      }
    });
  }, [dataSettings]);

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (typeof field === 'object') {
          const fieldValues = Object.values(field);
          return fieldValues.some((elem) => elem === true || elem?.value === true);
        }
        return field === true;
      });
    if (applicationFormChanged !== isDirty) dispatch(setApplicationFormChanged(isDirty));
  }, [Object.values(dirtyFields)]);

  const closeSaveModal = () => setSaveModalOpen(false);

  const onSubmit: SubmitHandler<TEditSettingsInputs> = async (data) => {
    if (data.redirect_uris.every((uri) => !uri.value)) {
      setError(`redirect_uris.0.value`, { message: 'Обязательное поле' });
      return;
    }

    if (Object.keys(errors).length) return;
    const payload = Object.entries(data).map(([key, value]) => {
      if (key === 'post_logout_redirect_uris' || key === 'redirect_uris') {
        return [
          key,
          data[key].reduce((dataAcc: string[], uri) => {
            if (uri.value) dataAcc.push(uri.value);
            return dataAcc;
          }, []),
        ];
      }

      return [key, value];
    });

    const isKeyOfGeneralSettings = (
      key:
        | string
        | number
        | boolean
        | string[]
        | File
        | {
            name: string;
            value: string;
          }[]
        | TWidgetColors
        | null,
    ) => {
      return (
        typeof key === 'string' &&
        [
          'registration_policy',
          'allow_everyone_to_create_applications',
          'authorize_only_admins',
          'default_public_profile_claims_oauth',
          'default_public_profile_claims_gravatar',
          'phone_provider_enabled',
        ].includes(key)
      );
    };

    await Promise.all([
      updateApplication({
        currentClientId: selectedClient.client_id,
        params: {
          ...Object.fromEntries(payload.filter(([key]) => !isKeyOfGeneralSettings(key))),
          grant_types: [
            EGrantTypes.authorization_code,
            EGrantTypes.refresh_token,
            EGrantTypes.device_flow,
          ],
          registration_access_token: selectedClient.registration_access_token?.jti,
          client_id: selectedClient.client_id,
          redirect_uris: selectedClient.redirect_uris,
          post_logout_redirect_uris: selectedClient.post_logout_redirect_uris,
        },
      }),
      editSettings(Object.fromEntries(payload.filter(([key]) => isKeyOfGeneralSettings(key)))),
    ]);

    dispatch(providerApi.util.invalidateTags(['Providers']));

    dispatch(
      settingsApi.endpoints.getSettings.initiate(undefined, {
        subscribe: false,
        forceRefetch: true,
      }),
    );
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  const handleCancelLicense = () => {
    setAddLicenseModalOpen(false);
  };

  const handleProviderClick = (providerItem: TProviderItem, providerScope: ProviderScope) => {
    if (
      providerScope === ProviderScope.login &&
      !providerItem.rule.allowedScopes.includes(ProviderScope.login)
    ) {
      return;
    }

    if (
      providerItem.provider.type !== MiscProviderType.CREDENTIALS &&
      providerItem.provider.type !== MiscProviderType.EMAIL &&
      providerItem.provider.type !== MiscProviderType.PHONE &&
      providerItem.provider.client_id === selectedClient.client_id
    ) {
      setSelectedProvider(providerItem);
      setSelectedProviderType(providerScope);
      setProviderModalOpen(true);
    }
  };

  const handleChangeRequiredProviders = async (provider: TOauthProvider | TMiscProvider) => {
    if (!userId || updateApplicationLoading || getApplicationFetching || !selectedClient) return;

    await updateApplication({
      currentClientId: selectedClient.client_id,
      params: {
        required_providers_ids: selectedClient.required_providers_ids.includes(String(provider.id))
          ? selectedClient.required_providers_ids.filter((id) => id !== String(provider.id))
          : [...selectedClient.required_providers_ids, String(provider.id)],
        grant_types: selectedClient.grant_types,
        registration_access_token: selectedClient.registration_access_token?.jti,
        client_id: selectedClient.client_id,
        redirect_uris: selectedClient.redirect_uris,
        post_logout_redirect_uris: selectedClient.post_logout_redirect_uris,
        require_signed_request_object: selectedClient.require_signed_request_object,
        request_uris: selectedClient.request_uris,
        id_token_signed_response_alg: selectedClient.id_token_signed_response_alg,
        response_types: selectedClient.response_types,
        introspection_endpoint_auth_method: selectedClient.introspection_endpoint_auth_method,
        require_auth_time: selectedClient.require_auth_time,
        revocation_endpoint_auth_method: selectedClient.revocation_endpoint_auth_method,
        token_endpoint_auth_method: selectedClient.token_endpoint_auth_method,
        subject_type: selectedClient.subject_type,
      },
    });
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  const listLicenseItems = licenseList?.map((license) => {
    const listProvidersNames = listLoginProviders.reduce((acc: string[], provider) => {
      license.providers.includes(provider.provider.id) && acc.push(provider.provider.name);
      return acc;
    }, []);

    return (
      <LicenseItem
        key={license.value}
        licenseName={license.value}
        licenseId={license.id}
        expirationTime={license.expiration_time}
        licenseKeys={license.flags}
        connectedProvidersList={listProvidersNames}
      />
    );
  });

  return (
    <>
      <div className={'wrapper-scroll'}>
        <div className={'content'}>
          {!!openedFromMenu && (
            <div className={styles['header-text']}>
              <CustomTypography className={clsx('font-golos', 'text-24-medium', styles.title)}>
                Настройки личного кабинета
              </CustomTypography>
              <CustomTypography
                style={{ marginBottom: 14 }}
                className={clsx('text-14')}
                color="grey"
              >
                Добавляйте способы входа, управляйте доступом к личному кабинету и настраивайте
                внешний вид виджета для авторизации пользователей.
              </CustomTypography>
            </div>
          )}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <EditApplicationHeader
                coverSrc={coverSrc}
                setCoverSrc={setCoverSrc}
                avatarSrc={avatarSrc}
                client={selectedClient}
                setAvatarSrc={setAvatarSrc}
                openedFromMenu={openedFromMenu}
              />
              <Accordion className={clsx(styles.panel, styles.accordion)}>
                <AccordionSummary
                  className={styles['accorion-summary']}
                  classes={{ content: styles['accorion-summary-content'] }}
                  expandIcon={
                    <IconButton size="large" disableRipple={false}>
                      <CustomIcon Icon={ArrowDownIcon} />
                    </IconButton>
                  }
                >
                  <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
                    Способы входа
                  </CustomTypography>
                </AccordionSummary>
                <AccordionDetails className={styles['accordion-details']}>
                  <Button
                    variant="custom2"
                    className={styles['add-button']}
                    onClick={() => {
                      setSelectedProviderType(ProviderScope.login);
                      setProviderModalOpen(true);
                    }}
                  >
                    Настроить
                  </Button>
                  <div className={styles.providers}>
                    {providersLoading &&
                      [null, null].map((_, index) => (
                        <div key={index} className={styles.provider}>
                          <IconWrapper>
                            <Skeleton width={20} height={30} />
                          </IconWrapper>
                          <div>
                            <CustomTypography className={clsx('text-14')}>
                              <Skeleton />
                            </CustomTypography>
                            <CustomTypography className={clsx('text-12')} color="grey">
                              OAuth 2
                            </CustomTypography>
                          </div>
                          <IconButton className={styles['icon-button-wrapper']}>
                            <CloseIcon className={styles['icon-button']} />
                          </IconButton>
                        </div>
                      ))}
                    {listLoginProviders
                      .filter((providerItem) => providerItem.provider.is_active)
                      .map((providerItem) => {
                        return (
                          <div
                            key={providerItem.provider.id}
                            className={styles.provider}
                            onClick={() => handleProviderClick(providerItem, ProviderScope.login)}
                          >
                            <div
                              style={{
                                backgroundImage: `url(${
                                  BACKEND_URL + '/' + providerItem.provider.avatar
                                })`,
                              }}
                              className={styles['provider-icon-wrapper']}
                            >
                              {!providerItem.provider.avatar &&
                                (providerItem.provider.type === 'PHONE' ? (
                                  <PhoneIcon />
                                ) : (
                                  <IdIcon />
                                ))}
                            </div>
                            <div className={styles['provider-name-wrapper']}>
                              <CustomTypography
                                className={clsx('text-14', styles['provider-name'])}
                              >
                                {providerItem.provider.name}
                              </CustomTypography>
                              <CustomTypography className={clsx('text-12')} color="grey">
                                {getProviderTitleByType(providerItem.provider.type)}
                              </CustomTypography>
                            </div>
                            {providerItem.rule.requireable && (
                              <>
                                {selectedClient.required_providers_ids.includes(
                                  String(providerItem.provider.id),
                                ) ? (
                                  <IconWithTooltip
                                    iconType="starFilled"
                                    description="Для входа в приложение не требуется
                                 наличие способа входа в профиле пользователя"
                                    action={(e) => {
                                      e?.stopPropagation();
                                      handleChangeRequiredProviders(providerItem.provider);
                                    }}
                                  />
                                ) : (
                                  <IconWithTooltip
                                    iconType="star"
                                    description="Для входа в приложение требуется
                                 наличие способа входа в профиле пользователя"
                                    action={(e) => {
                                      e?.stopPropagation();
                                      handleChangeRequiredProviders(providerItem.provider);
                                    }}
                                  />
                                )}
                              </>
                            )}
                            {providerItem.provider.is_active &&
                              !(
                                selectedClient.client_id === CLIENT_ID &&
                                providerItem.provider.type === MiscProviderType.CREDENTIALS
                              ) && (
                                <IconButton
                                  onClick={async (e) => {
                                    if (!userId) return;
                                    e.stopPropagation();
                                    await deactivateProvider({
                                      body: {
                                        providers: [parseInt(providerItem.provider.id, 10)],
                                      },
                                      client_id: selectedClient.client_id,
                                    });
                                    dispatch(
                                      clientApi.endpoints.getApplicationById.initiate(
                                        { client_id: selectedClient.client_id, user_id: userId },
                                        {
                                          subscribe: false,
                                          forceRefetch: true,
                                        },
                                      ),
                                    );
                                  }}
                                  className={styles['icon-button-wrapper']}
                                >
                                  <CloseIcon className={styles['icon-button']} />
                                </IconButton>
                              )}
                          </div>
                        );
                      })}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className={clsx(styles.panel, styles.accordion)}>
                <AccordionSummary
                  className={styles['accorion-summary']}
                  classes={{ content: styles['accorion-summary-content'] }}
                  expandIcon={
                    <IconButton size="large" disableRipple={false}>
                      <CustomIcon Icon={ArrowDownIcon} />
                    </IconButton>
                  }
                >
                  <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
                    Доверенные провайдеры
                  </CustomTypography>
                </AccordionSummary>
                <AccordionDetails className={styles['accordion-details']}>
                  <Button
                    variant="custom2"
                    className={styles['add-button']}
                    onClick={() => {
                      setSelectedProviderType(ProviderScope.trusted);
                      setProviderModalOpen(true);
                    }}
                  >
                    Настроить
                  </Button>
                  <div className={styles.providers}>
                    {providersLoading &&
                      [null, null].map((_, index) => (
                        <div key={index} className={styles.provider}>
                          <IconWrapper>
                            <Skeleton width={20} height={30} />
                          </IconWrapper>
                          <div>
                            <CustomTypography className={clsx('text-14')}>
                              <Skeleton />
                            </CustomTypography>
                            <CustomTypography className={clsx('text-12')} color="grey">
                              OAuth 2
                            </CustomTypography>
                          </div>
                          <IconButton className={styles['icon-button-wrapper']}>
                            <CloseIcon className={styles['icon-button']} />
                          </IconButton>
                        </div>
                      ))}
                    {listTrustedProviders.map((providerItem) => {
                      return (
                        <div
                          key={providerItem.provider.id}
                          className={styles.provider}
                          onClick={() => handleProviderClick(providerItem, ProviderScope.trusted)}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${
                                BACKEND_URL + '/' + providerItem.provider.avatar
                              })`,
                            }}
                            className={styles['provider-icon-wrapper']}
                          >
                            {!providerItem.provider.avatar &&
                              (providerItem.provider.type === 'PHONE' ? <PhoneIcon /> : <IdIcon />)}
                          </div>
                          <div className={styles['provider-name-wrapper']}>
                            <CustomTypography className={clsx('text-14', styles['provider-name'])}>
                              {providerItem.provider.name}
                            </CustomTypography>
                            <CustomTypography className={clsx('text-12')} color="grey">
                              {getProviderTitleByType(providerItem.provider.type)}
                            </CustomTypography>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className={clsx(styles.panel, styles.accordion)}>
                <AccordionSummary
                  className={styles['accorion-summary']}
                  classes={{ content: styles['accorion-summary-content'] }}
                  expandIcon={
                    <IconButton size="large" disableRipple={false}>
                      <CustomIcon Icon={ArrowDownIcon} />
                    </IconButton>
                  }
                >
                  <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
                    Настройка профиля пользователя
                  </CustomTypography>
                </AccordionSummary>
                <AccordionDetails className={styles['accordion-details']}>
                  <CustomTypography
                    className={clsx('text-14', styles['input-subtitle'])}
                    color="grey"
                  >
                    Настройте параметры полей профиля и добавьте идентификаторы, которые должны быть
                    у пользователя в профиле
                  </CustomTypography>
                  <CustomTypography
                    style={{ marginTop: 32 }}
                    className={clsx('text-17-regular', 'font-golos')}
                  >
                    Парольная политика
                  </CustomTypography>
                  <ListItem
                    className={styles['password-item']}
                    onClick={() => setSavePasswordPolicyModalOpen(true)}
                  >
                    <div className={styles['password-content']}>
                      <div className={styles['password-head']}>
                        <CustomTypography className={clsx('text-14', styles['password-title'])}>
                          Пароль
                        </CustomTypography>
                        <CustomTypography color="grey" className="text-12">
                          password
                        </CustomTypography>
                      </div>
                    </div>
                  </ListItem>
                  <CustomTypography
                    style={{ marginTop: 32 }}
                    className={clsx('text-17-regular', 'font-golos')}
                  >
                    Основная информация
                  </CustomTypography>
                  {listGeneralProfileFields}
                  <CustomTypography
                    style={{ marginTop: 32 }}
                    className={clsx('text-17-regular', 'font-golos')}
                  >
                    Дополнительная информация
                  </CustomTypography>
                  <Button
                    variant="custom2"
                    className={styles['add-button']}
                    onClick={() => setProfileModalOpen(true)}
                  >
                    Добавить
                  </Button>
                  {listCustomProfileFields}
                </AccordionDetails>
              </Accordion>
              <Accordion className={clsx(styles.panel, styles.accordion)}>
                <AccordionSummary
                  className={styles['accorion-summary']}
                  classes={{ content: styles['accorion-summary-content'] }}
                  expandIcon={
                    <IconButton size="large" disableRipple={false}>
                      <CustomIcon Icon={ArrowDownIcon} />
                    </IconButton>
                  }
                >
                  <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
                    Лицензия
                  </CustomTypography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ alignItems: 'flex-start' }}
                  className={styles['accordion-details']}
                >
                  <Button
                    variant="custom2"
                    className={styles['add-button']}
                    onClick={() => setAddLicenseModalOpen(true)}
                  >
                    Добавить лицензию
                  </Button>
                  <div
                    style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}
                  >
                    {listLicenseItems}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className={clsx(styles.panel, styles.accordion)}>
                <AccordionSummary
                  className={styles['accorion-summary']}
                  classes={{ content: styles['accorion-summary-content'] }}
                  expandIcon={
                    <IconButton size="large" disableRipple={false}>
                      <CustomIcon Icon={ArrowDownIcon} />
                    </IconButton>
                  }
                >
                  <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
                    Шаблоны писем
                  </CustomTypography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ alignItems: 'flex-start' }}
                  className={styles['accordion-details']}
                >
                  <Button
                    variant="custom2"
                    className={styles['add-button']}
                    onClick={() => setEmailTemplatesModalOpen(true)}
                  >
                    Настроить
                  </Button>
                </AccordionDetails>
              </Accordion>
              <Accordion className={clsx(styles.panel, styles.accordion)}>
                <AccordionSummary
                  className={styles['accorion-summary']}
                  classes={{ content: styles['accorion-summary-content'] }}
                  expandIcon={
                    <IconButton size="large" disableRipple={false}>
                      <CustomIcon Icon={ArrowDownIcon} />
                    </IconButton>
                  }
                >
                  <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
                    Sentry
                  </CustomTypography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ alignItems: 'flex-start' }}
                  className={styles['accordion-details']}
                >
                  <Button
                    variant="custom2"
                    className={styles['add-button']}
                    onClick={() => setSentryModalOpen(true)}
                  >
                    Настроить
                  </Button>
                </AccordionDetails>
              </Accordion>
              <Accordion className={clsx(styles.panel, styles.accordion)}>
                <AccordionSummary
                  className={styles['accorion-summary']}
                  classes={{ content: styles['accorion-summary-content'] }}
                  expandIcon={
                    <IconButton size="large" disableRipple={false}>
                      <CustomIcon Icon={ArrowDownIcon} />
                    </IconButton>
                  }
                >
                  <CustomTypography className={clsx('font-golos', 'text-17-regular')}>
                    Настройки доступа
                  </CustomTypography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ alignItems: 'flex-start' }}
                  className={styles['accordion-details']}
                >
                  <div className={styles['input-wrapper']}>
                    <div className={styles['radio-wrapper']}>
                      <Controller
                        control={control}
                        name={'authorize_only_admins'}
                        render={({ field: { onChange, value, ref } }) => (
                          <>
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <div>
                                  <CustomTypography className={clsx('text-14')}>
                                    Ограниченный доступ
                                  </CustomTypography>
                                  <CustomTypography className={clsx('text-12')} color="grey">
                                    Вход в личный кабинет доступен только для пользователей с
                                    правами “Администратор” {PROJECT_NAME}
                                  </CustomTypography>
                                </div>
                              }
                              value={true}
                              checked={!!value}
                              onChange={() => {
                                onChange(true);
                                setValue('authorize_only_admins', true, { shouldDirty: true });
                              }}
                            />
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <div>
                                  <CustomTypography className={clsx('text-14')}>
                                    Неограниченный доступ
                                  </CustomTypography>
                                  <CustomTypography className={clsx('text-12')} color="grey">
                                    Вход в личный кабинет не ограничен
                                  </CustomTypography>
                                </div>
                              }
                              value={false}
                              checked={!value}
                              onChange={() => onChange(false)}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className={styles['input-wrapper']}>
                    <CustomTypography className={clsx('text-14', styles['input-title'])}>
                      Запрет регистрации
                    </CustomTypography>
                    <div className={styles['radio-wrapper']}>
                      <Controller
                        control={control}
                        name={'registration_policy'}
                        render={({ field: { onChange, value, ref } }) => (
                          <>
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <CustomTypography className={clsx('text-14')}>
                                  Регистрация запрещена
                                </CustomTypography>
                              }
                              checked={value === RegistrationPolicyVariants.disabled}
                              onClick={() => onChange(RegistrationPolicyVariants.disabled)}
                            />
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <CustomTypography className={clsx('text-14')}>
                                  Регистрация разрешена
                                </CustomTypography>
                              }
                              checked={value === RegistrationPolicyVariants.allowed}
                              onClick={() => onChange(RegistrationPolicyVariants.allowed)}
                            />
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <CustomTypography className={clsx('text-14')}>
                                  Разрешена только авторегистрация
                                </CustomTypography>
                              }
                              checked={
                                value === RegistrationPolicyVariants.allowed_autoregistration_only
                              }
                              onClick={() =>
                                onChange(RegistrationPolicyVariants.allowed_autoregistration_only)
                              }
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className={styles['input-wrapper']}>
                    <CustomTypography className={clsx('text-14', styles['input-title'])}>
                      Регистрация пользователей с правами “Администратора”
                    </CustomTypography>
                    <div className={styles['radio-wrapper']}>
                      <Controller
                        control={control}
                        name={'allow_everyone_to_create_applications'}
                        render={({ field: { onChange, value, ref } }) => (
                          <>
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <CustomTypography className={clsx('text-14')}>
                                  Регистрация с правами администратора
                                </CustomTypography>
                              }
                              checked={!!value}
                              onChange={() => onChange(true)}
                            />
                            <CustomRadioButton
                              inputRef={ref}
                              className={styles.radio}
                              label={
                                <CustomTypography className={clsx('text-14')}>
                                  Регистрация без прав администратора
                                </CustomTypography>
                              }
                              checked={!value}
                              onChange={() => onChange(false)}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <EditApplicationFooter
                coverSrc={coverSrc}
                applicationFormChanged={applicationFormChanged}
                avatarSrc={avatarSrc}
                savedCallback={savedCallback}
                setSaveModalOpen={setSaveModalOpen}
              />
            </form>
          </FormProvider>
          <EditProviders
            close={() => setProviderModalOpen(false)}
            clearSelectedProvider={() => {
              setSelectedProvider(undefined);
              setSelectedProviderType(undefined);
            }}
            isOpen={providerModalOpen}
            selectedProvider={selectedProvider}
            providerType={selectedProviderType}
          />
          <EditEmailTemplates
            close={() => setEmailTemplatesModalOpen(false)}
            isOpen={isEmailTemplatesModalOpen}
            templates={emailTemplates}
          />
          <EditSentry
            close={() => setSentryModalOpen(false)}
            isOpen={isSentryModalOpen}
            params={sentry || { dsn: '', enabled: false, user_id: '' }}
          />
          <EditProfileField
            close={() => setProfileModalOpen(false)}
            clearSelectedProfileField={() => setSelectedProfileField(undefined)}
            isOpen={profileModalOpen}
            selectedProfile={selectedProfileField}
            claims={claims}
            phoneProvider={phoneProvider}
            emailProvider={emailProvider}
          />
          <EditRuleValidations
            close={() => setSavePasswordPolicyModalOpen(false)}
            field_name={'password'}
            isOpen={savePasswordPolicyModalOpen}
          />

          {addLicenseModalOpen && (
            <ModalAddLicense isOpen={addLicenseModalOpen} closeModal={handleCancelLicense} />
          )}
          <ModalWithAction
            title="Сохранение изменений"
            message="Изменения не сохранены. Продолжить без сохранения?"
            actionTitle="Продолжить"
            isOpen={saveModalOpen}
            onAction={() => {
              savedCallback.current?.();
              dispatch(setApplicationFormChanged(false));
              setSaveModalOpen(false);
            }}
            onClose={closeSaveModal}
          />
        </div>
      </div>
    </>
  );
};
