import React, { FC, useState, CSSProperties } from 'react';
import { connect } from 'react-redux';
import { cardHeight, Order } from 'src/enums';
import { TLogger, useLazyGetEventsLogQuery } from 'src/redux/services/logger';
import { queryLogProps } from 'src/redux/types';
import { RootState } from '../../redux/store';
import { CustomTypography } from '../custom/CustomTypography';
import { ModalInfo } from '../modal/ModalInfo';
import { ListItems } from '../shared/listElements';
import CardEventLog from './CardEventLog';
import { EventLogInfo } from './EventLogInfo';

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
});

interface IEventLogProps {
  isPersonal?: boolean;
  userId?: string;
}

const EventLogComponent: FC<IEventLogProps> = ({ 
  isPersonal,
  userId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<TLogger | null>(null);
  const [getEventsLog] = useLazyGetEventsLogQuery();

  const query = (offset: number, search?: string): queryLogProps => {
    const queryParams: queryLogProps = {
      sortBy: 'date',
      sortDirection: Order.DESC,
      limit: '10',
      offset,
      search: search || '',
    }
    if (isPersonal && userId) {
      queryParams.filter = JSON.stringify({user_id: parseInt(userId, 10)});
    }

    return queryParams;
  };

  const openModal = (value: TLogger) => {
    setSelectedEvent(value);
    setIsOpen(true);
  }

  return (
    <div data-id="event-logs">
      {userId ? (
        <>
          <ListItems<TLogger, queryLogProps>
            heightListItem={cardHeight.Desktop}
            padding={cardHeight.Indent}
            query={query}
            getItems={getEventsLog}
            rowElement={(items: TLogger[], index: number, style: CSSProperties) => (
              <CardEventLog 
                data={items} 
                index={index} 
                style={style} 
                height={cardHeight.Desktop} 
                padding={cardHeight.Indent}
                onClick={() => openModal(items[index])} isPersonal={isPersonal} 
              />
            )}
          />
          <ModalInfo isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <EventLogInfo selectedEvent={selectedEvent} />
          </ModalInfo>
        </>
      ) : (
        <CustomTypography>
          Загрузка...
        </CustomTypography>
      )}
    </div>
  );
};

export const EventLog = connect(mapStateToProps)(EventLogComponent);
