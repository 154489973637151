import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom-v5-compat';
import * as yup from 'yup';
import { isObjectEmpty, isOwner } from '../../helpers';
import { RootState } from '../../redux/rootReducer';
import {
  MiscProviderType,
  TCreateProvider,
  useCreateProviderMutation,
} from '../../redux/services/provider';
import { LicenseSelect } from '../applications/LicenseSelect';
import { CustomTypography } from '../custom/CustomTypography';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { ModalCloseOnly } from '../modal/ModalCloseOnly';
import { ModalWithAction } from '../modal/ModalWithAction';
import { SharedFormProvider } from '../shared/FormProvider';
import { ProviderSettingsSidePanel } from '../sidePanel/ProviderSettingsSidePanel';
import styles from './CreateProvider.module.css';
import { ProviderFooter } from './ProviderFooter';
import { ProviderHeader } from './ProviderHeader';

export type CreateKloudProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  auth_without_email?: boolean;
  password_required: boolean;
  is_public: boolean;
  path_to_avatar: string;
  external_client_id: string;
  external_client_secret: string;
  issuer?: string;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  issuer: yup
    .string()
    .url('Неверный формат ссылки')
    .min(1, 'Обязательное поле')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .nullable(true)
    .transform((v) => (typeof v === 'undefined' ? null : v)),
  external_client_id: yup
    .string()
    .max(255, 'Идентификатор не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Идентификатор не может содержать пробелы',
    }),
  external_client_secret: yup
    .string()
    .max(255, 'Секретный ключ не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Секретный ключ не может содержать пробелы',
    }),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
});

export const CreateKloudProvider: FC<TCreateProvider> = ({
  isOpen,
  close,
  pathToAvatar,
  scope,
}) => {
  const methods = useForm<CreateKloudProviderInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      auth_without_email: false,
      password_required: false,
      is_public: false,
      path_to_avatar: pathToAvatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields, errors },
    setError,
    clearErrors,
    reset,
    control,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | null>(null);
  const watchDescription = watch('description');
  const [createProvider, createResult] = useCreateProviderMutation();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const userRole = useSelector((state: RootState) => state.user.userProfile.role);

  useEffect(() => {
    if (createResult.isSuccess) close(true);
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        CreateKloudProviderInputs & { id: string; type: MiscProviderType.KLOUD }
      > = JSON.parse(text);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { type, avatar, id, ...restInputs } = provider || {};

      if (type !== MiscProviderType.KLOUD) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', avatar);
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<keyof Omit<CreateKloudProviderInputs, 'avatar'>>
          ).forEach((field) => {
            if (
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public'
            ) {
              return setValue(field, String(restInputs?.[field]) === 'true', {
                shouldDirty: !provider,
              });
            }
            setValue(field, restInputs?.[field] || '', { shouldDirty: true });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<CreateKloudProviderInputs> = (data) => {
    const { avatar, ...rest } = data;
    if (!isOwner(userRole)) {
      delete rest.auth_without_email;
    }
    createProvider({
      body: {
        type: MiscProviderType.KLOUD,
        ...rest,
        avatar: avatar ? avatar : null,
      },
      client_id: clientId,
    });
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Создать способ входа KLOUD"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <SharedFormProvider<CreateKloudProviderInputs>
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        handleCancel={handleClose}
        isDisabled={createResult.isLoading}
        acceptTitle="Создать"
      >
        <div className={styles['create-provider-form']}>
          <ProviderHeader
            type={scope}
            watchDescription={watchDescription}
            overrideImage={overrideImage}
            pathToAvatar={pathToAvatar}
            setAvatarError={setAvatarError}
            clearAvatarError={clearAvatarError}
            setAvatarLink={setAvatarLink}
            setAvatarValue={setAvatarValue}
            imgSrc={avatarSrc}
            setImgSrc={setAvatarSrc}
          />
          <LicenseSelect flag={MiscProviderType.KLOUD} />
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Идентификатор ресурса (client_id)
          </CustomTypography>
          <TextField
            {...register('external_client_id', {
              required: true,
              onChange: () => {
                if (errors.external_client_id) clearErrors('external_client_id');
              },
            })}
            className="custom"
            error={!!errors.external_client_id}
            helperText={errors.external_client_id ? errors.external_client_id.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Уникальный идентификатор подключаемого ресурса
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Секретный ключ (client_secret)
          </CustomTypography>
          <PasswordTextfield
            {...register('external_client_secret', {
              required: true,
              onChange: () => {
                if (errors.external_client_secret) clearErrors('external_client_secret');
              },
            })}
            className="custom"
            error={!!errors.external_client_secret}
            helperText={errors.external_client_secret ? errors.external_client_secret.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Секретный ключ ресурса
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Базовый адрес сервера авторизации (issuer)
          </CustomTypography>
          <TextField
            {...register('issuer', {
              required: true,
              onChange: () => {
                if (errors.issuer) clearErrors('issuer');
              },
            })}
            style={{ marginBottom: 32 }}
            className="custom"
            error={!!errors.issuer}
            helperText={errors.issuer ? errors.issuer.message : ''}
            fullWidth
            variant="standard"
          />
          <ProviderFooter type={MiscProviderType.KLOUD} clientId={clientId} />
        </div>
      </SharedFormProvider>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для KLOUD-провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
