import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import React, { CSSProperties, FC, memo } from 'react';
import { BACKEND_URL } from 'src/constants';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import styles from './Card.module.css';

interface ICardProps {
  height: number;
  padding: number;
  style: CSSProperties;
  content: JSX.Element;
  isImage?: boolean;
  avatarUrl: string | null;
  className?: string;
  onClick?: () => void;
}

const CardComponent: FC<ICardProps> = ({ 
  height, 
  padding,
  style, 
  content,
  isImage,
  avatarUrl,
  className,
  onClick,
}: ICardProps) => {
  const fullAvatarUrl = avatarUrl ? `${BACKEND_URL}/${avatarUrl}` : '';
  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <Box
      onClick={handleClick}
      style={{
        ...style,
        height: height,
        top: `${(typeof style.top === 'number' ? style.top : Number(style.top)) + padding}px`
      }}
      className={clsx(styles.card, className, onClick && styles.cardAction)}
    >
      {isImage && (
        <div className={styles.cardImageWrapper}>
          <div className={styles.cardImage}>
            {fullAvatarUrl ? <Avatar src={fullAvatarUrl} className={styles.avatar} /> : <CustomMuiIcon Icon={LayersOutlinedIcon} customColor={'grey'}/>}
          </div>
        </div>
      )}
      {content}
    </Box>
  );
};

/**
 * Компонент CardComponent отображает карточку с контентом и аватаром.
 * @param height - Высота карточки. (react-window)
 * @param style - Дополнительные стили для карточки. (react-window)
 * @param content - Контент, который будет отображаться внутри карточки.
 * @param isImage - Флаг, указывающий, отображать ли изображение.
 * @param avatarUrl - URL аватара, который будет отображаться в карточке. Если null, отображается avatar по умолчанию.
 * @param className - CSS класс для карточки.
 * @param onClick - Функция, которая будет вызвана при клике на карточку.
 */
export const Card = memo(CardComponent);
