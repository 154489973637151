import { createApi } from '@reduxjs/toolkit/query/react';
import { queryIdProps } from '../types';
import { baseQuery, createQuery } from '../helpers';

export type TShortClient = {
  client_id: string;
  name: string;
  description: string | null;
  domain: string;
  avatar: string | null;
  created_at: string;
};

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  tagTypes: ['Clients'],
  baseQuery: baseQuery('clients'),
  endpoints: (builder) => ({
    deleteSession: builder.mutation<'', queryIdProps>({
      query: ({ clientId, userId }) =>
        createQuery(`${clientId}/users/${userId}/sessions`, 'DELETE'),
    }),
  }),
});

export const {
  useDeleteSessionMutation
} = clientsApi;