import SearchIcon from '@mui/icons-material/Search';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, debounce, InputBase } from '@mui/material';
import clsx from 'clsx';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import styles from './SearchForm.module.css';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/query';
import { responseListItems } from 'src/redux/types';

interface SearchFormProps<T, L> {
  setSearchString: Dispatch<SetStateAction<string>>;
  query: (offset: number, search: string) => L;
  getItems: LazyQueryTrigger<QueryDefinition<L, any, any, responseListItems<T[]>, any>>;
  updateItems: (items: T[], totalCount: number) => void;
}

const SearchFormComponent = <T, L>({
  setSearchString,
  query,
  getItems,
  updateItems,
}: SearchFormProps<T, L>) => {
  const [active, setActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  
  const onSearch = async (value: string) => {
    setSearchString(value);
    const queryParams = query(0, value);
    const { data } = await getItems(queryParams);
    
    if (data) {
      updateItems(data.items, data.totalCount);
    }
  };

  const onSearchDebounce = useCallback(debounce(onSearch, 200), []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    onSearchDebounce(value);
  };

  return (
    <Box className={clsx(styles.search, active && styles.searchActive)} onClick={() => console.log(3)}>
      <SearchIcon className={styles.searchIcon} />
      <InputBase
        className={styles.searchInputWrapper}
        onBlur={() => setActive(false)}
        onFocus={() => setActive(true)}
        onChange={handleSearch}
        value={searchValue}
        classes={{ input: clsx('text-14', styles.searchInput) }}
        placeholder="Поиск…"
        inputProps={{ 'aria-label': 'search' }}
      />
      {searchValue && 
        <CloseOutlinedIcon 
          className={styles.closeIcon}
          onClick={() => {
            setSearchValue('');
            onSearch('');
          }}
        />
      }
    </Box>
  );
};

/**
 * Поле поиска с иконкой поиска и кнопкой очистки.
 * @param setSearchString - Функция для установки строки поиска (useState).
 * @param query - Функция для создания параметров запроса.
 * @param getItems - Функция для получения элементов.
 * @param updateItems - Функция для обновления элементов.
 */
export const SearchForm = (SearchFormComponent);