import React, { FC } from 'react';
import { connect } from 'react-redux';
import { cardHeight, Order, screenDevices } from 'src/enums';
import { querySortParams } from 'src/redux/types';
import { RootState } from '../../redux/store';
import { CustomTypography } from '../custom/CustomTypography';
import { ListItems } from '../shared/listElements';
import CardCatalog from './CardCatalog';
import { useLazyGetCatalogQuery } from 'src/redux/services/catalog';
import { TShortClient } from 'src/redux/services/clients';

const mapStateToProps = (state: RootState) => ({
  screenSize: state.app.screenSize,
  userId: state.user.userProfile.id,
});

interface IProfileCatalogProps {
  screenSize: number;
  userId?: string;
}

const ProfileCatalogComponent: FC<IProfileCatalogProps> = ({ screenSize, userId }) => {
  const [getCatalog] = useLazyGetCatalogQuery();
  const query = (offset: number, search?: string): querySortParams => {
    return {
      sortBy: 'created_at',
      sortDirection: Order.DESC,
      limit: '10',
      offset,
      search: search || '',
    };
  };

  const getCardHeight = (screenSize: number) => {
    switch (screenSize) {
      case screenDevices.Desktop:
        return 112;
      case screenDevices.Tablet:
        return 140;
      default:
        return 170;
    }
  };

  const height = getCardHeight(screenSize);

  return (
    <div data-id="catalog">
      {userId ? (
        <ListItems<TShortClient, querySortParams>
          heightListItem={height}
          padding={cardHeight.Indent}
          query={query}
          getItems={getCatalog}
          rowElement={(items: TShortClient[], index: number, style: React.CSSProperties) => (
            <CardCatalog data={items} index={index} style={style} height={height} padding={cardHeight.Indent} />
          )}
        />
      ) : (
        <CustomTypography>Загрузка...</CustomTypography>
      )}
    </div>
  );
};

export const ProfileCatalog = connect(mapStateToProps)(ProfileCatalogComponent);
