import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/query';
import clsx from 'clsx';
import React, { CSSProperties, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import {  responseListItems } from 'src/redux/types';
import { CustomTypography } from '../custom/CustomTypography';
import styles from './listElements.module.css';
import { SearchForm } from './SearchForm';

interface IListElementsProps<T, L> {
  heightListItem: number;
  padding: number;
  query: (offset: number, search: string) => L;
  getItems: LazyQueryTrigger<QueryDefinition<L, any, any, responseListItems<T[]>, any>>;
  rowElement: (items: T[], index: number, style: React.CSSProperties, action: (items: T[], totalCount: number) => void) => JSX.Element;
}

const ListElementsComponent = <T, L>({
  heightListItem,
  padding,
  query,
  getItems,
  rowElement,
}: IListElementsProps<T, L>) => {
  const infiniteLoaderRef = useRef(null);
  const [items, setItems] = useState<T[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [emptyState, setEmptyState] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const SEARCH_HEIGHT = 50;

  useEffect(() => {
    loadItems(true, 0);
  }, []);

  const isItemLoaded = (index: number) => !!items[index];

  const loadMoreItems = async (startIndex: number) => {
    if (items.length > 0) {
      await loadItems(false, startIndex);
    }
  };

  const loadItems = async (setTotal: boolean, startIndex: number) => {
    const queryParams = query(startIndex, searchString);
    const { data } = await getItems(queryParams);
    if (!data?.totalCount) {
      setEmptyState(true);
    }

    if (data) {
      setItems((prevItems) => [...prevItems, ...data.items]);
      setTotal && setTotalCount(data.totalCount);
    }
  }

  const updateItems = useCallback((items: T[], totalCount: number) => {
    setTotalCount(totalCount);
    setItems(items);
    setEmptyState(totalCount === 0);
  }, []);
  
  const innerElementType = forwardRef<HTMLDivElement, { style: CSSProperties; children?: React.ReactNode }>(
    ({ style, children }, ref) => (
      <div
        ref={ref}
        style={{ ...style, height: `${parseFloat(style.height as string) + padding}px` }}
      >
        {children}
      </div>
    )
  );
  innerElementType.displayName = 'InnerElementType';

  return (
    <Box 
      data-id="list-items"
      className={clsx(styles.wrapper, 'wrapper')}
    >
      <Box className={'content'}>
        <SearchForm<T, L> 
          query={query}
          getItems={getItems}
          setSearchString={setSearchString}
          updateItems={updateItems}
        />
        {emptyState ? (
          <Box className={styles.emptyState}>
            <SearchIcon className={styles.emptyIcon} />
            <CustomTypography className={clsx('text-17-regular', 'font-golos')} color="grey">
              По вашему запросу ничего не найдено
            </CustomTypography>
          </Box>
        ) : (
          <InfiniteLoader
            ref={infiniteLoaderRef}
            isItemLoaded={isItemLoaded}
            itemCount={totalCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <AutoSizer disableWidth>
                {({ height }: {height: number}) => (
                  <List
                    height={height - SEARCH_HEIGHT}
                    itemSize={heightListItem + padding}
                    width={"100%"}
                    innerElementType={innerElementType}
                    className={styles.list}
                    itemCount={totalCount}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    itemData={items}
                  >
                    {({data, index, style}) => rowElement(data, index, style, updateItems)}
                  </List>
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        )}
      </Box>
    </Box>
  );
};

/**
 * Компонент ListElementsComponent отображает список элементов с бесконечной прокруткой.
 * @template T - Тип данных, отображаемых в списке.
 * @template L - Тип параметров запроса.
 * @param heightListItem - Высота каждого элемента списка.
 * @param padding - Отступы между элементами списка.
 * @param query - Функция для создания параметров запроса с пагинацией.
 * @param getItems - Функция для получения элементов списка.
 * @param rowElement - Функция для рендеринга элемента списка.
 */
export const ListItems = (ListElementsComponent);

