import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import TurnLeftOutlinedIcon from '@mui/icons-material/TurnLeftOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import clsx from 'clsx';
import React, { CSSProperties, Dispatch, FC, SetStateAction, useState } from 'react';
import { formatDate } from 'src/helpers';
import { useDeleteSessionMutation } from 'src/redux/services/clients';
import { TScope, useRevokeScopesMutation } from 'src/redux/services/users';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import { CustomMuiIcon } from '../custom/CustomMuiIcon';
import { CustomTypography } from '../custom/CustomTypography';
import { ModalDelete } from '../modal/ModalDelete';
import { Card } from '../shared/Card';
import { MenuControls } from '../shared/MenuControls';
import styles from './CardScope.module.css';
import { getScopeProps } from './utils';

interface CardScopeProps {
  userId: string;
  data: TScope[];
  index: number;
  style: CSSProperties;
  height: number;
  padding: number;
  updateScope: (items: TScope[], totalCount: number) => void;
}

const CardScope: FC<CardScopeProps> = ({ userId, data, index, style, height, padding, updateScope }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDeleteScopesModalOpen, setIsDeleteScopesModalOpen] = useState(false);
  const [isDeleteSessionsModalOpen, setIsDeleteSessionsModalOpen] = useState(false);
  const [revokeScopes] = useRevokeScopesMutation();
  const [deleteSession] = useDeleteSessionMutation();
  const { client, scopes, created_at } = data[index] || {};
  const id = client?.client_id;
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteScopes = async () => {
    try {
      await revokeScopes({ userId, clientId: id }).unwrap();
      const filteredScopes = data.filter((scope) => scope.client.client_id !== id);
      updateScope(filteredScopes, filteredScopes.length);
    } catch (error) {
      console.error('rejected', error);
    }
    //setIsDeleteScopesModalOpen(false);
  };

  const handleDeleteSessions = async () => {
    try {
      await deleteSession({ userId, clientId: id }).unwrap();
    } catch (error) {
      console.error('rejected', error);
    }
    setIsDeleteSessionsModalOpen(false);
  };

  const scopeControls = [
    {
      icon: LogoutOutlinedIcon,
      title: 'Завершить сеансы',
      action: () => {
        setIsDeleteSessionsModalOpen(true)
        handleClose();
      },
      addDivider: true,
    },
    {
      icon: TurnLeftOutlinedIcon,
      title: 'Отозвать разрешения',
      action: async () => {
        setIsDeleteScopesModalOpen(true);
        handleClose();
      },
    },
  ];

  return (
    <Card
      height={height}
      style={style}
      padding={padding}
      avatarUrl={client?.avatar}
      isImage
      content={
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.clientInfo}>
              <Box className={styles.clientMainInfo}>
                <Link className={styles.clientTitle} href={client?.domain}>
                  {client?.name || 'Loading...'}
                </Link>
                <CustomTypography color="grey" className={clsx('text-12')}>
                  {formatDate(created_at) || 'Loading...'}
                </CustomTypography>
              </Box>
              <div className={styles.clientAddInfo}>
                {client?.description && (
                  <div className={styles.clientDescription}>
                    <CustomTypography color="grey" className={clsx('text-12')}>
                      Описание:
                    </CustomTypography>
                    <CustomTypography className={clsx('text-12')}>
                      {client?.description}
                    </CustomTypography>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.clientScopesInfo}>
              <CustomTypography color="grey" className={clsx('text-12')}>
                Выданные разрешения:
              </CustomTypography>
              <Box className={styles.scopesList}>
                {scopes?.map((scope, index) => {
                  const scopeProps = getScopeProps(scope);
                  return (
                    <IconWithTooltip
                      key={index}
                      muiIcon={scopeProps.icon}
                      muiIconStyles={styles.scopeIcon}
                      customTitle={scopeProps.description}
                      customStyleButton={styles.scopeButton}
                    />
                  );
                })}
              </Box>
            </div>
          </div>
          <div className={styles.moreButtonWrapper}>
            <Button aria-describedby={id} onClick={handleClick} className={styles.moreButton}>
              <CustomMuiIcon Icon={MoreHorizOutlinedIcon} customColor={'grey'} />
            </Button>
            <MenuControls
              anchorEl={anchorEl}
              handleClosePopover={handleClose}
              isOpen={isOpen}
              controls={scopeControls}
            />
          </div>
          <ModalDelete
            isOpen={isDeleteScopesModalOpen}
            onAction={handleDeleteScopes}
            onClose={() => setIsDeleteScopesModalOpen(false)}
            title={'Отозвать разрешения'}
            customActionTitle="Отозвать"
            mainMessage={[
              `Приложение ${client?.name} больше не сможет получать доступ к вашим данным`,
            ]}
          />
          <ModalDelete
            isOpen={isDeleteSessionsModalOpen}
            onAction={handleDeleteSessions}
            onClose={() => setIsDeleteSessionsModalOpen(false)}
            title={'Удалить сессии'}
            mainMessage={[`Все ваши сессии и токены для приложения ${client?.name} будут удалены`]}
          />
        </div>
      }
    />
  );
};

export default CardScope;
