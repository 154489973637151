import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './CreateProvider.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { OauthProviderType, useCreateProviderMutation } from '../../redux/services/provider';
import { isObjectEmpty, isOwner } from '../../helpers';
import { BACKEND_URL } from '../../constants';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { ScopeChips } from '../ScopeChips';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { useParams } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { ProviderScope } from '../../redux/services/settings';
import { ModalWithAction } from '../modal/ModalWithAction';
import { IconWithTooltip } from '../shared/IconWithTooltip';
import { ModalCloseOnly } from '../modal/ModalCloseOnly';
import { CustomTypography } from '../custom/CustomTypography';
import { useTheme } from '@mui/material';
import { BaseSidePanel } from '../sidePanel/BaseSidePanel';
import { SharedFormProvider } from '../shared/FormProvider';

export type CreateProviderParamsInputs = {
  external_client_secret: string;
  external_client_id: string;
  issuer: string;
  mapping: string;
  userinfo_endpoint: string;
  token_endpoint: string;
  authorization_endpoint: string;
};

export type CreateProviderInputs = {
  description: string;
  name: string;
  scopes: string;
  avatar: File | null;
  provider_id: string;
  auto_registration?: boolean;
  auth_without_email?: boolean;
  password_required: boolean;
  is_public: boolean;
  external_client_secret: string;
  external_client_id: string;
  issuer: string;
  mapping: string;
  userinfo_endpoint: string;
  token_endpoint: string;
  authorization_endpoint: string;
};

const schema = yup.object({
  name: yup
    .string()
    .max(50, 'Название не может превышать 50 символов')
    .required('Обязательное поле')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  external_client_id: yup
    .string()
    .max(255, 'Идентификатор не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Идентификатор не может содержать пробелы',
    }),
  external_client_secret: yup
    .string()
    .max(255, 'Секретный ключ не может превышать 255 символов')
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Секретный ключ не может содержать пробелы',
    }),
  authorization_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  token_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  issuer: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  userinfo_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
  mapping: yup
    .string()
    .max(2000, 'Длина выражения для сопоставления артибутов не может превышать 2000 символов'),
});

export type TChips = {
  key: string;
  value: string;
  changing?: boolean;
};

type TCreateProvider = {
  isOpen: boolean;
  close: (createChooseProvider?: boolean) => void;
  scope: ProviderScope;
};

export const CreateProvider: FC<TCreateProvider> = ({ isOpen, close, scope }) => {
  const methods = useForm<CreateProviderInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      auth_without_email: false,
      auto_registration: false,
      password_required: false,
      is_public: false,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
  } = methods;

  const theme = useTheme();
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const [chips, setChips] = useState<TChips[]>([]);
  const watchDescription = watch('description');
  const [createProvider, createResult] = useCreateProviderMutation();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const userRole = useSelector((state: RootState) => state.user.userProfile.role);

  useEffect(() => {
    if (createResult.isSuccess) close(true);
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
      setChips([]);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        CreateProviderInputs & {
          id: string;
          type: OauthProviderType;
          params: CreateProviderParamsInputs;
        }
      > = JSON.parse(text);
      const { type, avatar, scopes, params, ...restInputs } = provider || {};
      delete restInputs.id;

      if (typeof type === 'string' && type in OauthProviderType) {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', avatar);
        }

        if (scopes)
          setChips(
            scopes
              ?.split(' ')
              .map((scope, index) => ({ value: scope, key: String(index), changing: false })) || [],
          );

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<
              keyof Omit<CreateProviderInputs, 'avatar' | 'scopes' | 'params'>
            >
          ).forEach((field) => {
            if (
              field === 'auto_registration' ||
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public'
            ) {
              return setValue(field, restInputs?.[field] === true, { shouldDirty: true });
            }

            setValue(field, restInputs?.[field] || '', { shouldDirty: true });
          });
        }
        if (params) {
          (Object.keys(params) as Array<keyof CreateProviderParamsInputs>).forEach((field) => {
            setValue(field, params?.[field] || '', { shouldDirty: true });
          });
        }
      } else {
        setClipboardModalOpen(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<CreateProviderInputs> = (data) => {
    const { avatar, ...rest } = data;
    if (!isOwner(userRole)) {
      delete rest.auth_without_email;
      delete rest.auto_registration;
    }
    const scopes = chips.map((chip) => chip.value).join(' ');
    createProvider({
      body: {
        ...rest,
        type: OauthProviderType.CUSTOM,
        avatar: avatar ? avatar : null,
        ...(scopes && { scopes }),
      },
      client_id: clientId,
    });
  };

  return (
    <BaseSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Создать способ входа"
      isNoBackdrop
    >
      <SharedFormProvider<CreateProviderInputs>
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        handleCancel={handleClose}
        isDisabled={createResult.isLoading}
        acceptTitle="Создать"
      >
        <div className={styles['create-provider-form']}>
          <ProviderHeader
            type={scope}
            watchDescription={watchDescription}
            overrideImage={overrideImage}
            setAvatarError={setAvatarError}
            clearAvatarError={clearAvatarError}
            setAvatarValue={setAvatarValue}
            imgSrc={avatarSrc}
            setImgSrc={setAvatarSrc}
          />
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Идентификатор ресурса (client_id)
          </CustomTypography>
          <TextField
            {...register('external_client_id', {
              required: true,
              onChange: () => {
                if (errors.external_client_id) clearErrors('external_client_id');
              },
            })}
            className="custom"
            error={!!errors.external_client_id}
            helperText={errors.external_client_id ? errors.external_client_id.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Уникальный идентификатор подключаемого ресурса
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Секретный ключ (client_secret)
          </CustomTypography>
          <PasswordTextfield
            {...register('external_client_secret', {
              required: true,
              onChange: () => {
                if (errors.external_client_secret) clearErrors('external_client_secret');
              },
            })}
            className="custom"
            error={!!errors.external_client_secret}
            helperText={errors.external_client_secret ? errors.external_client_secret.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Секретный ключ ресурса
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Redirect URI
          </CustomTypography>
          <TextField
            value={BACKEND_URL + '/api/interaction/code'}
            disabled
            className="custom"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconWithTooltip
                    iconType="copy"
                    action={() => {
                      navigator.clipboard.writeText(BACKEND_URL + '/api/interaction/code');
                    }}
                  />
                </InputAdornment>
              ),
              classes: {
                disabled: styles['input-wrapper-disabled'],
              },
            }}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Cсылка должна быть указана в настройках внешних способов входа для корректной
            аутентификации пользователя
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Базовый адрес сервера авторизации (issuer)
          </CustomTypography>
          <TextField
            {...register('issuer', {
              required: true,
              onChange: () => {
                if (errors.issuer) clearErrors('issuer');
              },
            })}
            style={{ marginBottom: 32 }}
            className="custom"
            error={!!errors.issuer}
            helperText={errors.issuer ? errors.issuer.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Адрес авторизации (authorization_endpoint)
          </CustomTypography>
          <TextField
            {...register('authorization_endpoint', {
              required: true,
              onChange: () => {
                if (errors.authorization_endpoint) clearErrors('authorization_endpoint');
              },
            })}
            className="custom"
            error={!!errors.authorization_endpoint}
            helperText={errors.authorization_endpoint ? errors.authorization_endpoint.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Адрес, на который пользователь переадресовывается для авторизации
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Адрес выдачи токена (token_endpoint)
          </CustomTypography>
          <TextField
            {...register('token_endpoint', {
              required: true,
              onChange: () => {
                if (errors.token_endpoint) clearErrors('token_endpoint');
              },
            })}
            className="custom"
            error={!!errors.token_endpoint}
            helperText={errors.token_endpoint ? errors.token_endpoint.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Ресурс, обеспечивающий выдачу токенов
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Адрес получения информации о пользователе (userinfo_endpoint)
          </CustomTypography>
          <TextField
            {...register('userinfo_endpoint', {
              required: true,
              onChange: () => {
                if (errors.userinfo_endpoint) clearErrors('userinfo_endpoint');
              },
            })}
            className="custom"
            error={!!errors.userinfo_endpoint}
            helperText={errors.userinfo_endpoint ? errors.userinfo_endpoint.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Ресурс, который возвращает информацию о текущем пользователе
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles['input-title'])}>
            Запрашиваемые разрешения
          </CustomTypography>
          <ScopeChips chips={chips} setChips={setChips} />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Перечень разрешений, которые должны быть получены при обращении к поставщику
            идентификации. Для добавления разрешения введите его имя и нажмите Enter.
          </CustomTypography>
          <ProviderFooter type={OauthProviderType.CUSTOM} clientId={clientId} />
        </div>
      </SharedFormProvider>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для Oauth-провайдера."
      />
    </BaseSidePanel>
  );
};
