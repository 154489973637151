import React, { FC } from 'react';
import { connect } from 'react-redux';
import { cardHeight, Order, screenDevices } from 'src/enums';
import { TScope, useLazyGetUserScopesQuery } from 'src/redux/services/users';
import { queryProps } from 'src/redux/types';
import { RootState } from '../../redux/store';
import { CustomTypography } from '../custom/CustomTypography';
import { ListItems } from '../shared/listElements';
import CardScope from './CardScope';

const mapStateToProps = (state: RootState) => ({
  screenSize: state.app.screenSize,
  userId: state.user.userProfile.id,
});

interface IProfileScopesProps {
  screenSize: number;
  userId?: string;
}

const ProfileScopesComponent: FC<IProfileScopesProps> = ({ screenSize, userId }) => {
  const [getScopes] = useLazyGetUserScopesQuery();
  const query = (offset: number, search?: string): queryProps => {
    return {
      sortBy: 'created_at',
      sortDirection: Order.DESC,
      limit: '10',
      offset,
      userId: String(userId),
      search: search || '',
    };
  };

  const getCardHeight = (screenSize: number) => {
    switch (screenSize) {
      case screenDevices.Desktop:
        return cardHeight.Desktop;
      case screenDevices.Tablet:
        return 140;
      default:
        return 170;
    }
  };

  const height = getCardHeight(screenSize);

  return (
    <div data-id="scopes">
      {userId ? (
        <ListItems<TScope, queryProps>
          heightListItem={height}
          padding={cardHeight.Indent}
          query={query}
          getItems={getScopes}
          rowElement={(
            items: TScope[],
            index: number,
            style: React.CSSProperties,
            action: (items: TScope[], totalCount: number) => void,
          ) => (
            <CardScope
              userId={userId}
              data={items}
              index={index}
              style={style}
              height={height}
              padding={cardHeight.Indent}
              updateScope={action}
            />
          )}
        />
      ) : (
        <CustomTypography>Загрузка...</CustomTypography>
      )}
    </div>
  );
};

export const ProfileScopes = connect(mapStateToProps)(ProfileScopesComponent);
