import React, { FC, useEffect, useState } from 'react';
import styles from './CreateProvider.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TCreateProvider,
  useCreateProviderMutation,
} from '../../redux/services/provider';

import { isObjectEmpty } from '../../helpers';
import { ProviderHeader } from './ProviderHeader';
import { useParams } from 'react-router-dom-v5-compat';
import { ModalWithAction } from '../modal/ModalWithAction';
import { ModalCloseOnly } from '../modal/ModalCloseOnly';
import { ProviderSettingsSidePanel } from '../sidePanel/ProviderSettingsSidePanel';
import { LicenseSelect } from '../applications/LicenseSelect';
import { ProviderFooter } from './ProviderFooter';
import { SharedFormProvider } from '../shared/FormProvider';

export type CreateQRCodeProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  path_to_avatar: string;
  license_id: string;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  license_id: yup.string(),
});

export const CreateQRCodeProvider: FC<TCreateProvider> = ({
  isOpen,
  close,
  pathToAvatar,
  scope,
}) => {
  const methods = useForm<CreateQRCodeProviderInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      license_id: '',
      description: '',
      path_to_avatar: pathToAvatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const watchDescription = watch('description');
  const [createProvider, createResult] = useCreateProviderMutation();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);

  useEffect(() => {
    if (createResult.isSuccess) close(true);
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        Omit<CreateQRCodeProviderInputs, 'avatar'> & {
          id: string;
          isPublic: boolean;
          client_id: string;
          avatar: string;
          type: MiscProviderType.QRCODE;
        }
      > = JSON.parse(text);
      const { type, avatar, ...restInputs } = provider || {};
      delete restInputs.id;
      delete restInputs.client_id;
      if (type !== MiscProviderType.QRCODE) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }
        if (restInputs) {
          (
            Object.keys(restInputs) as Array<keyof Omit<CreateQRCodeProviderInputs, 'avatar'>>
          ).forEach((field) => {
            setValue(field, restInputs?.[field] || '', { shouldDirty: true });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<CreateQRCodeProviderInputs> = (data) => {
    const { avatar, ...rest } = data;

    createProvider({
      body: {
        type: MiscProviderType.QRCODE,
        ...rest,
        avatar: avatar ? avatar : null,
      },
      client_id: clientId,
    });
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Создать способ входа QR-код"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <SharedFormProvider<CreateQRCodeProviderInputs>
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        handleCancel={handleClose}
        isDisabled={createResult.isLoading}
        acceptTitle="Создать"
      >
        <div className={styles['create-provider-form']}>
          <ProviderHeader
            type={scope}
            watchDescription={watchDescription}
            overrideImage={overrideImage}
            setAvatarError={setAvatarError}
            clearAvatarError={clearAvatarError}
            setAvatarValue={setAvatarValue}
            setAvatarLink={setAvatarLink}
            imgSrc={avatarSrc}
            setImgSrc={setAvatarSrc}
            pathToAvatar={pathToAvatar}
          />
          <LicenseSelect flag={MiscProviderType.QRCODE} />
          <ProviderFooter type={MiscProviderType.QRCODE} clientId={clientId} />
        </div>
      </SharedFormProvider>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для QR-код провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
