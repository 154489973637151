import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './CreateProvider.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TCreateProvider,
  useCreateProviderMutation,
} from '../../redux/services/provider';
import { isObjectEmpty, isOwner } from '../../helpers';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import TextField from '@mui/material/TextField';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { useParams } from 'react-router-dom-v5-compat';
import { RootState } from '../../redux/rootReducer';
import { useSelector } from 'react-redux';
import { ModalWithAction } from '../modal/ModalWithAction';
import { ModalCloseOnly } from '../modal/ModalCloseOnly';
import { CustomTypography } from '../custom/CustomTypography';
import { ProviderSettingsSidePanel } from '../sidePanel/ProviderSettingsSidePanel';
import { SharedFormProvider } from '../shared/FormProvider';

export type CreateSmsProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  auth_without_email?: boolean;
  password_required: boolean;
  is_public: boolean;
  path_to_avatar: string;
  admin_login: string;
  admin_password: string;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
  admin_login: yup
    .string()
    .required('Обязательное поле')
    .max(33, 'Логин не может превышать 33 символа'),
  admin_password: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Пароль не может превышать 50 символов'),
});

export const CreateSmsProvider: FC<TCreateProvider> = ({ isOpen, close, pathToAvatar, scope }) => {
  const methods = useForm<CreateSmsProviderInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      auth_without_email: false,
      password_required: false,
      is_public: false,
      path_to_avatar: pathToAvatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields, errors },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | null>(null);
  const watchDescription = watch('description');
  const [createProviderMutation, createResult] = useCreateProviderMutation();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const userRole = useSelector((state: RootState) => state.user.userProfile.role);

  useEffect(() => {
    if (createResult.isSuccess) close(true);
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        CreateSmsProviderInputs & { id: string; type: MiscProviderType.SMS }
      > = JSON.parse(text);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { type, avatar, id, ...restInputs } = provider || {};

      if (type !== MiscProviderType.SMS) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', avatar);
        }

        if (restInputs) {
          (Object.keys(restInputs) as Array<keyof Omit<CreateSmsProviderInputs, 'avatar'>>).forEach(
            (field) => {
              if (
                field === 'auth_without_email' ||
                field === 'password_required' ||
                field === 'is_public'
              ) {
                return setValue(field, String(restInputs?.[field]) === 'true', {
                  shouldDirty: !provider,
                });
              }
              setValue(field, restInputs?.[field] || '', { shouldDirty: true });
            },
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<CreateSmsProviderInputs> = (data) => {
    const { avatar, ...rest } = data;
    if (!isOwner(userRole)) {
      delete rest.auth_without_email;
    }
    createProviderMutation({
      body: {
        type: MiscProviderType.SMS,
        ...rest,
        avatar: typeof avatar === 'string' ? null : avatar,
      },
      client_id: clientId,
    });
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Создать способ входа СМС"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <SharedFormProvider<CreateSmsProviderInputs>
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        handleCancel={handleClose}
        isDisabled={createResult.isLoading}
        acceptTitle='Создать'
      >
        <div className={styles['create-provider-form']}>
          <ProviderHeader
            type={scope}
            watchDescription={watchDescription}
            overrideImage={overrideImage}
            pathToAvatar={pathToAvatar}
            setAvatarError={setAvatarError}
            clearAvatarError={clearAvatarError}
            setAvatarLink={setAvatarLink}
            setAvatarValue={setAvatarValue}
            imgSrc={avatarSrc}
            setImgSrc={setAvatarSrc}
          />
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Логин
          </CustomTypography>
          <TextField
            {...register('admin_login', {
              required: true,
              onChange: () => {
                if (errors.admin_login) clearErrors('admin_login');
              },
            })}
            className="custom"
            error={!!errors.admin_login}
            helperText={errors.admin_login ? errors.admin_login.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Логин от личного кабинета сервисного центра
          </CustomTypography>
          <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
            Пароль
          </CustomTypography>
          <PasswordTextfield
            {...register('admin_password', {
              required: true,
              onChange: () => {
                if (errors.admin_password) clearErrors('admin_password');
              },
            })}
            className="custom"
            error={!!errors.admin_password}
            helperText={errors.admin_password ? errors.admin_password.message : ''}
            fullWidth
            variant="standard"
          />
          <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
            Пароль от личного кабинета сервисного центра
          </CustomTypography>
          <ProviderFooter type={MiscProviderType.SMS} clientId={clientId} />
        </div>
      </SharedFormProvider>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для СМС-провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
