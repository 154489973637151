import React, { MouseEvent, ReactElement, useState } from 'react';
import styles from './UserItem.module.css';
import { TUser } from 'src/redux/services/client';
import { CustomTypography } from '../custom/CustomTypography';
import { getImageURL, getRoleName } from 'src/helpers';
import { IconButton, Popover, Typography } from '@mui/material';
import { ReactComponent as ActionsIcon } from '../../icons/Actions.svg';
import clsx from 'clsx';
import UserActionsPopover from './UserActionsPopover';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/rootReducer';

type TUserItem = {
  user: Partial<TUser>;
  role: string;
  setChangePermissionsModalOpen: (isOpen: boolean) => void;
  setDeleteUsersSessionsModalOpen: (isOpen: boolean) => void;
  setBlockUsersModalOpen: (isOpen: boolean) => void;
  handleDeleteButton: () => Promise<void>;
  setUserToActions: () => void;
  actionPopoverDisabled: boolean;
};
const UserItem = ({
  user,
  role,
  setChangePermissionsModalOpen,
  setDeleteUsersSessionsModalOpen,
  setBlockUsersModalOpen,
  handleDeleteButton,
  actionPopoverDisabled,
  setUserToActions,
}: TUserItem) => {
  const [popoverOriginTop, setPopoverOriginTop] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const avatar = getImageURL(user.picture);
  const isMobile = useSelector((state: RootState) => state.app.isMobile);

  const getUserStatus = (user?: TUser) => {
    if (user?.blocked)
      return (
        <CustomTypography className={clsx('text-14')} color="error">
          Заблокированный
        </CustomTypography>
      );
    const deleted = user?.deleted;
    if (deleted) {
      const deleteDate = new Date(deleted);
      deleteDate.setDate(deleteDate.getDate() + 30);

      return (
        <CustomTypography className={clsx('text-14')} color="grey">
          Будет удален {format(deleteDate, 'dd MMMM y г. в HH:mm', { locale: ru })}
        </CustomTypography>
      );
    }

    return <CustomTypography className="text-14">Активный</CustomTypography>;
  };

  const handleOpenPopover = (
    event: MouseEvent<HTMLButtonElement>,
    user: { user: Partial<TUser>; role: string },
  ) => {
    event.stopPropagation();
    if (event.clientY + 180 > document.body.clientHeight) {
      setPopoverOriginTop(true);
    } else setPopoverOriginTop(false);
    setUserToActions();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isMobile ? (
        <div className={styles['user-container']}>
          <div className={styles['mobile-header']}>
            <div className={styles['name-container']}>
              <div
                className={styles['app-icon-wrapper']}
                style={{
                  backgroundImage: avatar ? `url(${avatar})` : undefined,
                }}
              >
                {!user?.picture && (
                  <div className={styles['app-icon-default']}>
                    {user.nickname
                      ?.split(' ')
                      .map((name: string) => name[0]?.toUpperCase())
                      .join('')}
                  </div>
                )}
              </div>
              <div className={styles.column}>
                <CustomTypography className={clsx(styles['overflow-ellipsis'], 'text-14')}>
                  {(user.nickname || '').trim()
                    ? user.nickname
                    : ((user.given_name || '') + ' ' + (user.family_name || '')).trim() ||
                      'Нет имени'}
                </CustomTypography>
                <CustomTypography className={'text-12'} color="grey">
                  ID {user.id}
                </CustomTypography>
              </div>
            </div>
            <IconButton
              disabled={actionPopoverDisabled}
              onClick={(event) => handleOpenPopover(event, { user: user, role: role })}
              className={clsx(styles['open-actions-icon'], {
                [styles['active-actions-icon']]: isOpen,
              })}
            >
              <ActionsIcon />
            </IconButton>
          </div>
          <div>
            <CustomTypography className={'text-12'} color="grey">
              Права
            </CustomTypography>
            <CustomTypography className={'text-14'}>{getRoleName(role)}</CustomTypography>
          </div>
          <div>
            <CustomTypography className={'text-12'} color="grey">
              Статус
            </CustomTypography>
            {getUserStatus(user as TUser)}
          </div>
          <UserActionsPopover
            popoverOriginTop={popoverOriginTop}
            anchorEl={anchorEl}
            handleClosePopover={handleClosePopover}
            isOpen={isOpen}
            userToActions={{ user: user, role: role }}
            setChangePermissionsModalOpen={setChangePermissionsModalOpen}
            setDeleteUsersSessionsModalOpen={setDeleteUsersSessionsModalOpen}
            setBlockUsersModalOpen={setBlockUsersModalOpen}
            handleDeleteButton={handleDeleteButton}
          />
        </div>
      ) : (
        <div className={styles['user-container']}>
          <div className={styles['info-container']}>
            <div className={styles['name-container']}>
              <div
                className={styles['app-icon-wrapper']}
                style={{
                  backgroundImage: avatar ? `url(${avatar})` : undefined,
                }}
              >
                {!user?.picture && (
                  <div className={styles['app-icon-default']}>
                    {user.nickname
                      ?.split(' ')
                      .map((name: string) => name[0]?.toUpperCase())
                      .join('')}
                  </div>
                )}
              </div>
              <div className={styles['column-s']}>
                <CustomTypography className={clsx(styles['overflow-ellipsis'], 'text-14')}>
                  {(user.nickname || '').trim()
                    ? user.nickname
                    : ((user.given_name || '') + ' ' + (user.family_name || '')).trim() ||
                      'Нет имени'}
                </CustomTypography>
                <CustomTypography className={'text-12'} color="grey">
                  ID {user.id}
                </CustomTypography>
              </div>
            </div>
            <div className={styles.column}>
              <CustomTypography className={'text-12'} color="grey">
                Права
              </CustomTypography>
              <CustomTypography className={clsx(styles['overflow-ellipsis'], 'text-14')}>
                {getRoleName(role)}
              </CustomTypography>
            </div>
            <div className={styles.column}>
              <CustomTypography className={'text-12'} color="grey">
                Статус
              </CustomTypography>
              {getUserStatus(user as TUser)}
            </div>
          </div>
          <UserActionsPopover
            popoverOriginTop={popoverOriginTop}
            anchorEl={anchorEl}
            handleClosePopover={handleClosePopover}
            isOpen={isOpen}
            userToActions={{ user: user, role: role }}
            setChangePermissionsModalOpen={setChangePermissionsModalOpen}
            setDeleteUsersSessionsModalOpen={setDeleteUsersSessionsModalOpen}
            setBlockUsersModalOpen={setBlockUsersModalOpen}
            handleDeleteButton={handleDeleteButton}
          />
          <IconButton
            disabled={actionPopoverDisabled}
            onClick={(event) => handleOpenPopover(event, { user: user, role: role })}
            className={clsx(styles['open-actions-icon'], {
              [styles['active-actions-icon']]: isOpen,
            })}
          >
            <ActionsIcon />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default UserItem;
